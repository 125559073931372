<script>
  import ProductDetailAccordionItem from './ProductDetailAccordionItem.svelte';

  export let product;
  export let productInfo = [];

  let selectedIndex = null;
  function setSelected(index) {
    if (selectedIndex === index.detail) {
      selectedIndex = null;
      return;
    }
    selectedIndex = index.detail;
  }

  $: {
    productInfo = [];
    if (product.longDescription) {
      productInfo.push({
        label: 'Product Description',
        content: product.longDescription.replace(/(?:\r\n|\r|\n)/g, '<br />'),
      });
    }

    let temp = [];
    product.fabricComposition ? temp.push(product.fabricComposition) : '';
    product.paddingComposition ? temp.push('Padding: ' + product.paddingComposition) : '';
    product.liningComposition ? temp.push('Lining: ' + product.liningComposition) : '';
    temp.length > 0 ? temp.push('') : '';
    product.washingInstructions ? temp.push(product.washingInstructions) : '';
    product.bleachingInstructions ? temp.push(product.bleachingInstructions) : '';
    product.tumbleDryingInstructions ? temp.push(product.tumbleDryingInstructions) : '';
    product.ironingInstructions ? temp.push(product.ironingInstructions) : '';
    product.cleaningInstructions ? temp.push(product.cleaningInstructions) : '';

    if (temp.length) {
      productInfo.push({label: 'Product Care', content: temp.join('<br>')});
    }
  }
</script>

<div class="px-8 md:px-24 self-center max-w-[94.5rem] my-8">
  {#each productInfo.map((x) => ({...x, product})) as entry, index}
    <ProductDetailAccordionItem {entry} {index} {selectedIndex} on:selected={setSelected} />
  {/each}
</div>
