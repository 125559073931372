<script>
  import ProductSuggestionCard from '../product/components/ProductSuggestionCard.svelte';

  export let options = [];

  export let suggestionTitle = 'Title';
  export let gapSizeBetweenItems = 0.125;
  export let itemsInMobileView = 2;
  export let itemsInDesktopView = 4;
  export let showHeader = false;

  let selectedOptionIndex = 0;

  function selectOption(i) {
    selectedOptionIndex = i;
  }

  $: selectedProducts = options[selectedOptionIndex].products;
</script>

{#if showHeader}
  <div class="text-left px-4 pt-2">
    <p class="body-2">{suggestionTitle}</p>
    <div class="body-2 flex flex-wrap flex-row gap-4 my-4 ">
      {#each options as option, i}
        {#if i === selectedOptionIndex}
          {option.label}
        {:else}
          <div class="cursor-pointer text-[#a9a9a9]" on:click={() => selectOption(i)}>
            {option.label}
          </div>
        {/if}
      {/each}
    </div>
  </div>
{/if}
<div
  class="grid grid-cols-{itemsInMobileView} gap-[{gapSizeBetweenItems}rem] auto-rows-auto md:grid-cols-{itemsInDesktopView}"
>
  {#each selectedProducts as product, i}
    <ProductSuggestionCard
      linkUrl={product.linkUrl}
      title={product.itemTitle}
      imageUrl={product.imageUrl}
      price={product.itemPrice}
      {itemsInMobileView}
      {itemsInDesktopView}
      indexItem={i}
    />
  {/each}
</div>

<style>
</style>
