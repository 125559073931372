<script>
  import HamburgerIcon from '../../assets/svg/HamburgerIcon.svelte';
  import Logotype from '../../assets/svg/Logotype.svelte';
  import MobileMenu from './MobileHeader/MobileMenu.svelte';
  import FeedbackButton from '../../components/FeedbackButton.svelte';
  import {createEventDispatcher} from 'svelte';
  import {cartOpen} from '../../cart/services/cartStore';
  import {fade} from 'svelte/transition';

  const defaultSelectedMenuType = 'category';

  export let menuRootNodes;
  export let cartItemCount;
  export let filterCount;
  export let selectedMenuType = null;
  export let filterButtonVisible = false;
  let y = 0;

  let isMenuVisible = false;
  const dispatch = createEventDispatcher();

  function menuOpened() {
    if (!selectedMenuType) selectedMenuType = defaultSelectedMenuType;

    dispatch('menuOpened');
  }

  function menuClosed() {
    isMenuVisible = false;
    selectedMenuType = null;

    dispatch('menuClosed');
  }

  function cartToggled(e) {
    e.preventDefault();
    cartOpen.update((x) => !x);
  }

  function showFilter() {
    selectedMenuType = 'filter';
    isMenuVisible = true;
    menuOpened();
  }

  function applyFilterSelection(event) {
    dispatch('apply-filter-selection', event.detail);
  }

  $: isLogoVisible = y <= 0 || isMenuVisible;
</script>

<svelte:window bind:scrollY={y} />

{#if isLogoVisible}
  <div
    class="logo-wrapper"
    in:fade={{duration: 400}}
    out:fade={{duration: 400}}
    on:click={menuClosed}
  >
    <Logotype mobile="true" />
  </div>
{/if}
<header class="bottom-nav-wrapper text-style-4">
  <div class="flex items-center">
    <a class="text-black visited:text-black hover:text-black mr-6" href="/" on:click={cartToggled}>
      Cart ({cartItemCount})
    </a>
    {#if filterButtonVisible}
      <a
        hidden
        class="text-black visited:text-black hover:text-black"
        on:click={showFilter}
        class:hidden={isMenuVisible && selectedMenuType === 'filter'}
      >
        Filter ({filterCount})
      </a>
    {/if}
    <FeedbackButton />
  </div>
  <HamburgerIcon bind:isMenuVisible on:menuOpened={menuOpened} on:menuClosed={menuClosed} />
</header>

<div class="mobile-header-wrapper">
  <MobileMenu
    bind:isMenuVisible
    {menuRootNodes}
    {selectedMenuType}
    on:apply-filter-selection={applyFilterSelection}
    on:close-menu={menuClosed}
  />
</div>

<style>
  .mobile-header-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    align-items: flex-start;
    justify-content: space-between;
    top: 0;
    position: fixed;
    z-index: 10;
    align-self: flex-end;
  }

  .logo-wrapper {
    pointer-events: auto;
    margin-left: 1rem;
    margin-top: 1.5rem;
    z-index: 100;
    position: fixed;
  }

  .bottom-nav-wrapper {
    pointer-events: auto;
    z-index: 30;
    padding: 0.75rem 1.25rem;
    background-color: white;
    width: 100%;
    bottom: 0;
    position: fixed;
    display: flex;
    height: 4rem;
    justify-content: space-between;
  }

  .bottom-nav-wrapper > a {
    font-family: 'JLSemiBold', sans-serif;
  }
</style>
