<script>
  /**
   * url for the video
   *  @type {string}
   */
  export let videoUrl;
  /**
   /**
   * url for the video
   *  @type {string}
   */
  export let bannerText;
  /**
   * url for cta
   *  @type {string}
   */
  export let ctaUrl;
  /**
   * cta text
   *  @type {string}
   */
  export let ctaText;

  export let align;
  export let justify;

  function isValidString(str) {
    if (str === undefined) return false;
    if (str === null) return false;
    if (str === '') return false;
    return true;
  }

  $: isHeadingVisible = isValidString(bannerText);
  $: isCtaVisible = isValidString(ctaText);
</script>

<a href={ctaUrl}>
  <div class="flex items-{align} justify-{justify} banner-overlay md:web">
    <figure>
      <video
        src={videoUrl}
        autoplay="autoplay"
        muted="muted"
        class="video-banner"
        playsinline
        loop="loop"
      />
    </figure>
    <div class="overlay">
      {#if isHeadingVisible}
        <h2 class="heading-2">{bannerText}</h2>
      {/if}
      {#if isCtaVisible}
        <p class="text-style-4 cta-margin"><a class="menu-link" href={ctaUrl}>{ctaText}</a></p>
      {/if}
    </div>
  </div>
</a>

<style>
  :root {
    --visible-screen-height: calc(100vh - 4rem);
  }

  @media (min-width: 768px) {
    :root {
      --visible-screen-height: calc(100vh);
    }
  }

  .banner-overlay {
    height: var(--visible-screen-height);
  }

  .overlay {
    text-align: left;
    position: absolute;
    margin: 5.125rem 1.875rem;
  }

  .overlay .text-style-4 {
    letter-spacing: 0.1rem;
  }

  a.menu-link:after {
    background-color: var(--jl-text-white);
  }

  .overlay > p > a {
    text-decoration: none;
    color: var(--jl-text-white);
  }

  .banner-overlay:hover > .overlay a:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  video {
    height: var(--visible-screen-height);
    width: 100vw;
    object-fit: cover;
  }

  .heading-2,
  .text-style-4 {
    color: var(--jl-white);
  }

  .heading-2 {
    line-height: 200%;
  }

  .text-style-4 {
    font-weight: 600;
  }

  p.cta-margin {
    margin-left: 0.125rem;
  }
</style>
