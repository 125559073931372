<script>
  import {cartOpen} from '../../../cart/services/cartStore';
  import FeedbackButton from '../../../components/FeedbackButton.svelte';
  export let cartItemCount = 0;

  const toggleMenu = (e) => {
    e.preventDefault();
    cartOpen.update((x) => !x);
  };
</script>

<nav class="text-style-4">
  <ul class="flex flex-nowrap justify-center">
    <li class="mr-4">
      <FeedbackButton />
    </li>
    <li>
      <a class="menu-link" href="/cart" on:click={toggleMenu}>
        Cart ({cartItemCount})
      </a>
    </li>
  </ul>
</nav>

<style>
  li + li {
    margin-left: 0.5rem;
  }
</style>
