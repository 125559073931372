<script>
  import Logotype from '../../assets/svg/Logotype.svelte';
  import SubNavigation from './WebHeader/SubNavigation.svelte';
  import CategoryMenu from './WebHeader/CategoryMenu.svelte';

  export let menuRootNodes = [];
  export let cartItemCount;
  export let headerType;
  export let shouldOffsetHeader;

  let selectedNodeIndex = undefined;
  $: isMenuVisible = selectedNodeIndex !== undefined;
  $: selectedNode = menuRootNodes[selectedNodeIndex];

  let y = 0;
  let hasMouseHover = false;

  function openMenu(index) {
    if (selectedNodeIndex !== index) selectedNodeIndex = index;
  }

  function closeMenu() {
    selectedNodeIndex = undefined;
  }

  function handleMouseOver() {
    hasMouseHover = true;
  }
  function handleMouseOut() {
    hasMouseHover = false;
  }

  $: isTransparent = y <= 0 && !hasMouseHover && !isMenuVisible && headerType === 'Transparent';
  $: isBlurred = !isMenuVisible && headerType === 'Blurred';
  $: hasHeaderOffset = shouldOffsetHeader || headerType === 'Solid White';
</script>

<div
  class="header-wrapper"
  class:transparent={isTransparent}
  class:blurred={isBlurred}
  on:mouseover={handleMouseOver}
  on:mouseout={handleMouseOut}
>
  <header class="grid grid-cols-3">
    <section class="flex justify-start">
      <nav class="center-menu">
        <ul class="categories-wrapper items-center flex justify-center">
          {#each menuRootNodes as node, index}
            <li id={index} on:mouseover={() => openMenu(index)} on:focus={() => openMenu(index)}>
              <a
                class="text-style-4"
                on:click={closeMenu}
                href="/category/{node.linkValue || node.label}">{node.label}</a
              >
            </li>
          {/each}
        </ul>
      </nav>
    </section>
    <section class="flex justify-center">
      <Logotype />
    </section>
    <section class="flex justify-end">
      <SubNavigation {cartItemCount} />
    </section>
  </header>
  {#if isMenuVisible}
    <CategoryMenu on:click={closeMenu} on:mouse-leave={closeMenu} parentNode={selectedNode} />
  {/if}
</div>
{#if hasHeaderOffset}
  <div class="hidden md:block">
    <div class="header-offset" />
  </div>
{/if}
<svelte:window bind:scrollY={y} />

<style>
  :root {
    --web-header-height: 4rem;
  }

  .header-wrapper {
    position: fixed;
    background: var(--jl-offwhite);
    z-index: 1;
    width: 100%;
    transition: 0.3s ease-in-out;
  }

  .header-wrapper.transparent > header {
    background-color: transparent;
    transition: 0.3s ease-in-out;
    transition-delay: 800ms;
  }

  .header-wrapper.transparent {
    background-color: transparent;
    transition: 0.3s ease-in-out;
    transition-delay: 800ms;
  }
  .header-wrapper.blurred {
    background-color: transparent;
    backdrop-filter: blur(50px);
  }
  .header-wrapper.blurred > header {
    background-color: rgba(255, 255, 255, 0.7);
  }

  header {
    height: var(--web-header-height);
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding: 0 1.875rem;
  }

  .categories-wrapper a {
    color: var(--jl-black);
    margin: 0 0.5rem;
    display: inline-block;
    position: relative;
  }

  .categories-wrapper a:hover {
    text-decoration: none;
  }

  .categories-wrapper a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0.0625rem;
    top: 1.0625rem;
    bottom: 0;
    left: 0;
    background-color: var(--jl-black);
    transform-origin: bottom right;
    transition: transform 0.5s ease;
  }

  .categories-wrapper a:hover:after,
  .categories-wrapper a.active:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .header-offset {
    height: var(--web-header-height);
  }
</style>
