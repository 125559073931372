<script>
  import {createEventDispatcher} from 'svelte';
  import MobileProductFilterAccordionItem from './MobileProductFilterAccordionItem.svelte';
  import ProductFilterGroup from './../../../../product/components/productFilter/ProductFilterGroup.svelte';

  const dispatch = createEventDispatcher();

  export let filters = [];
  export let filterSelection = {};

  let selectedIndex = null;
  function setSelected(index) {
    if (selectedIndex === index.detail) {
      selectedIndex = null;
      return;
    }
    selectedIndex = index.detail;
  }

  function updateFilterSelection(event) {
    dispatch('update-filter-selection', event.detail);
  }
</script>

<div>
  {#each filters as filter, index}
    <MobileProductFilterAccordionItem
      label={filter.label}
      {index}
      {selectedIndex}
      on:selected={setSelected}
    >
      <ProductFilterGroup
        {filter}
        selectedValues={filterSelection[filter.name]}
        on:update-filter-selection={updateFilterSelection}
      />
    </MobileProductFilterAccordionItem>
  {/each}
</div>

<style>
</style>
