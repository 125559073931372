<script>
  export let expanded = true;
</script>

<span class="toggle-icon {expanded}"><span class="horizontal" /><span class="vertical" /></span>

<style>
  :root {
    --accordion-icon-size: 0.75rem;
    --accordion-half-icon-size: calc(0.75rem / 2);
  }

  .section {
    border-bottom: 0.03125rem solid var(--jl-grey);
  }

  ul {
    margin-bottom: 1rem;
  }

  li {
    text-align: start;
    padding: 0.5rem 0;
  }

  button {
    justify-content: space-between;
    height: 3.125rem;
    align-items: center;
    width: 100%;
    text-align: left;
    display: flex;
    border: none;
  }

  span.toggle-icon {
    display: inline;
    width: var(--accordion-icon-size);
    height: var(--accordion-icon-size);
  }

  span.toggle-icon > span.horizontal {
    content: '';
    display: block;
    position: absolute;
    width: var(--accordion-icon-size);
    height: 0.0825rem;
    transform: translateY(var(--accordion-half-icon-size));
    background-color: var(--jl-black);
    transition: transform 0.3s linear;
  }

  span.toggle-icon > span.vertical {
    content: '';
    display: block;
    position: absolute;
    width: var(--accordion-icon-size);
    height: 0.0825rem;
    transform: translateY(var(--accordion-half-icon-size)) rotateZ(-90deg);
    background-color: var(--jl-black);
    transition: transform 0.3s linear;
  }

  span.toggle-icon.true > span.horizontal {
    transform: translateY(var(--accordion-half-icon-size)) rotateZ(-180deg);
  }

  span.toggle-icon.true > span.vertical {
    transform: translateY(var(--accordion-half-icon-size)) rotateZ(-180deg);
  }
</style>
