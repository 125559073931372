<script>
  // THIS FILE IS JUST A SANDBOX DO NOT USE FOR PRODUCTION CODE!!!

  import {onMount} from 'svelte';

  import InspirationalComponent from '../SplitScreenX3.svelte';
  import ContentfulProductSuggestion from '../ProductSuggestionBlock.svelte';

  import ContentService from '../../services/contentService.js';

  const contentService = new ContentService();

  const _svelteComponentTable = {
    InspirationalBlock: InspirationalComponent,
    ProductSuggestionBlock: ContentfulProductSuggestion,
  };
  let svelteComponentMap = [];

  function resolveSvelteComponent(pageComponentType) {
    return _svelteComponentTable[pageComponentType];
  }

  onMount(() => {
    contentService
      .getCartBySlug('cart-page')
      .then((page) => page.components)
      .then((components) =>
        components.map((component) => [resolveSvelteComponent(component.type), component])
      )
      .then((pairs) => {
        svelteComponentMap = pairs;
      });
  });
</script>

<div>
  <div>
    {#each svelteComponentMap as [component, parameters]}
      <section>
        <svelte:component this={component} {...parameters} />
      </section>
    {/each}
  </div>
</div>
