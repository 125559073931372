<script>
  import {createEventDispatcher} from 'svelte';
  import {fade} from 'svelte/transition';

  const dispatch = createEventDispatcher();
  export let overlayTailwind = '';
  export let wrapperTailwind = '';
  export let verticalEdge = 'top';
  export let isScrollDisabled = false;

  function onClose() {
    dispatch('close');
  }
</script>

<div
  class="z-[200] w-screen h-screen fixed {verticalEdge}-0 left-0 flex {overlayTailwind} items-start bg-black/30"
  on:click={onClose}
  in:fade={{duration: 300}}
  out:fade={{duration: 300}}
>
  <div class="max-h-full bg-white relative max-w-lg4 {wrapperTailwind}" on:click|stopPropagation>
    <slot />
  </div>
</div>

<svelte:head>
  {#if isScrollDisabled}
    <style>
      body {
        overflow: hidden;
      }
    </style>
  {/if}
</svelte:head>
