<script>
  import {slide} from 'svelte/transition';
  import {createEventDispatcher} from 'svelte';
  import ExpandIcon from '../../../../assets/svg/ExpandIcon.svelte';
  import viewport from '../../../../utils/viewPortObserver.js';

  const dispatch = createEventDispatcher();

  export let entry = {label: 'Label', content: 'Content'};
  export let index = null;
  export let selectedIndex = null;
  $: isOpen = index === selectedIndex;
  $: isInViewPort = false;
  const toggle = ({target}) => {
    dispatch('selected', index);
    if (!isInViewPort) {
      setTimeout(() => {
        target.scrollIntoView({behavior: 'smooth', block: 'center'});
      }, 300);
    }
  };
</script>

<div class="section">
  <button on:click|preventDefault={toggle} aria-expanded={isOpen} class="text-style-1" id={index}>
    {entry.label}
    <ExpandIcon expanded={isOpen} />
  </button>
  <div>
    {#if isOpen}
      <p
        use:viewport
        on:enterViewport={() => (isInViewPort = true)}
        on:exitViewport={() => (isInViewPort = false)}
        class="body-3"
        transition:slide={{duration: 300}}
      >
        {@html entry.content}
      </p>
    {/if}
  </div>
</div>

<style>
  .section {
    border-bottom: 0.03125rem solid var(--jl-light-grey);
  }

  p {
    margin-bottom: 1rem;
  }

  button {
    justify-content: space-between;
    height: 3.125rem;
    align-items: center;
    width: 100%;
    text-align: left;
    display: flex;
    border: none;
  }
</style>
