<script>
  import ExpandIcon from '../../assets/svg/ExpandIcon.svelte';

  import {slide} from 'svelte/transition';
  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  export let section;
  export let index = null;
  export let selectedIndex = null;

  $: isOpen = index === selectedIndex;
  const toggle = () => {
    dispatch('selected', index);
  };
</script>

<div class="section md:spacing">
  <button on:click={toggle} aria-expanded={isOpen} class="text-style-1">
    {section.heading}
    <ExpandIcon expanded={isOpen} />
  </button>
  <div class="header hidden md:flex">
    <h6 class="text-style-1">{section.heading}</h6>
  </div>
  <div class="text-style-4">
    {#if isOpen}
      <ul transition:slide={{duration: 300}}>
        {#each section.links as link}
          {#if link.icon}
            <li>
              <i class="icon {link.icon}" /><a href={link.linkURL} target="_blank">{link.label}</a>
            </li>
          {:else}
            <li>
              {#if link.linkURL}
                <a href={link.linkURL}>{link.label}</a>
              {:else}
                <span>{link.label}</span>
              {/if}
            </li>
          {/if}
        {/each}
      </ul>
    {/if}
  </div>
</div>

<style>
  .section {
    border-bottom: 0.03125rem solid var(--jl-grey);
  }

  ul {
    margin-bottom: 1rem;
  }

  li {
    text-align: start;
    padding: 0.5rem 0;
  }

  button {
    justify-content: space-between;
    height: 3.125rem;
    align-items: center;
    width: 100%;
    text-align: left;
    display: flex;
    border: none;
  }

  span {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
</style>
