const imageTransformer = function () {};

imageTransformer.getThumbnail = function (url) {
  return url ? url + '/thumbnail' : url;
};

imageTransformer.getPreview = function (url) {
  return url ? url + '/preview' : url;
};

imageTransformer.getOriginal = function (url) {
  return url;
};

export default imageTransformer;
