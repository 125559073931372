<script>
  export let disable = false;
</script>

<div class="increment-icon">
  <div class="wrapper">
    <div class="line {disable}" />
  </div>
</div>

<style>
  .increment-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .line {
    width: 0.7rem;
    height: 0.07rem;
    background-color: var(--jl-black);
  }

  .line.true {
    background-color: var(--jl-grey);
  }
</style>
