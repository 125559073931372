<script>
  import ExpandIcon from '../../../../assets/svg/ExpandIcon.svelte';

  import {slide} from 'svelte/transition';
  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  export let label;
  export let index = null;
  export let selectedIndex = null;

  $: isOpen = index === selectedIndex;
  const toggle = () => {
    dispatch('selected', index);
  };
</script>

<div class="section">
  <button on:click={toggle} aria-expanded={isOpen} class="text-style-1">
    {label}
    <ExpandIcon expanded={isOpen} />
  </button>
  {#if isOpen}
    <div transition:slide={{duration: 300}}>
      <slot />
    </div>
  {/if}
</div>

<style>
  .section {
    border-bottom: 0.03125rem solid var(--jl-grey);
  }

  .section > div {
    padding-bottom: 1rem;
    position: relative;
  }

  button {
    justify-content: space-between;
    height: 3.125rem;
    align-items: center;
    width: 100%;
    text-align: left;
    display: flex;
    border: none;
  }
</style>
