<script>
  import CheckoutButton from './CheckoutButton.svelte';
  import CheckoutDetails from './CheckoutDetails.svelte';
  import parsePrice from '../../../utils/priceParser.js';

  export let numberOfItems;
  export let totalPrice;
  export let currencyCode;
  export let checkoutUrl;
  let totalTitle = 'TOTAL';
</script>

<div class="checkout-wrapper">
  <div class="checkout-information-wrapper">
    <div class="invisible h-0 md:visible md:h-full">
      <CheckoutDetails {numberOfItems} {totalPrice} {currencyCode} />
    </div>
    <div class="total-wrapper">
      <span class="total-text heading-5"
        >{totalTitle} <span class="text-grey body-1">(incl. VAT)</span></span
      >
      <span class="price-text body-1">{parsePrice(totalPrice)} {currencyCode}</span>
    </div>
  </div>
  <CheckoutButton isCartEmpty={numberOfItems === 0} {checkoutUrl} />
</div>

<style>
  .checkout-wrapper {
    height: 100%;
  }

  .checkout-information-wrapper {
    display: flex;
    flex-direction: column;
  }

  .total-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;
    justify-content: space-between;
  }

  .text-grey {
    color: var(--jl-middle-grey);
  }
</style>
