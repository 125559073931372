<script>
  import {createEventDispatcher} from 'svelte';
  import {slide} from 'svelte/transition';

  import CategoryMenuAccordion from './CategoryMenuAccordion.svelte';
  import ArrowDown from './../../../../assets/svg/ArrowDown.svelte';

  const dispatch = createEventDispatcher();

  export let menuRootNodes = [];

  let selectedNodeIndex = undefined;
  $: selectedNode = menuRootNodes[selectedNodeIndex];

  function selectNode(nodeIndex) {
    if (selectedNodeIndex === nodeIndex) return (selectedNodeIndex = undefined);
    selectedNodeIndex = nodeIndex;
  }

  function closeMenu() {
    dispatch('close-menu');
  }

  function getNodeUrl(node) {
    const nodeValue = node.linkValue || node.label;
    return `/category/${nodeValue}`.replace(/ /g, '');
  }
</script>

<section>
  {#each menuRootNodes as node, nodeIndex}
    <div class="category flex-wrap">
      <div class="category-title">
        <a
          class="heading-2"
          class:inactive={nodeIndex !== selectedNodeIndex &&
            typeof selectedNodeIndex !== 'undefined'}
          class:active={nodeIndex === selectedNodeIndex}
          href={`${getNodeUrl(node)}`}
          on:click={closeMenu}
        >
          <span>{node.label}</span>
        </a>
        <button on:click={() => selectNode(nodeIndex)} class="arrow-icon">
          <ArrowDown height="8" width="12" selected={nodeIndex === selectedNodeIndex} />
        </button>
      </div>
      {#if nodeIndex === selectedNodeIndex}
        <div transition:slide={{duration: 300}}>
          <CategoryMenuAccordion parentNode={selectedNode} on:close-menu={closeMenu} />
        </div>
      {/if}
    </div>
  {/each}
</section>

<style>
  section {
    border-top: 0.0625rem solid var(--jl-light-grey);
  }
  .category-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-bottom: 0.0625rem solid var(--jl-light-grey);
  }
  .category-title > a {
    height: 2.8125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    text-decoration: none;
    flex: 1 1 auto;
    line-height: 100%;
  }
  .arrow-icon {
    border: none;
    padding: 1rem 0 1rem 1rem;
  }
  .category-title > a > span {
    position: relative;
  }

  .category-title > a.inactive {
    color: var(--jl-middle-grey);
  }

  button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 6.25rem;
  }
</style>
