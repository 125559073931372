<script>
  import {checkPassword} from './service';
  import Logotype from '../assets/svg/Logotype.svelte';

  const login = (e) => {
    if (e) e.preventDefault();
    if (!checkPassword(pw)) {
      pw = '';
      wrongPw = true;
      return;
    }

    localStorage.acv1 = 'true';
    window.location = window.location; // eslint-disable-line
  };
  let wrongPw = false;
  $: pw = '';
  const btnText = 'login';
</script>

<header>
  <Logotype />
</header>
<div class="wrapper p-10 md:p-20">
  <h1>Welcome to our new site</h1>
  <p>Please enter the password that has been provided to you</p>
  {#if !!wrongPw}
    <p class="wrong-password">Wrong password</p>
  {:else}
    <p class="wrong-password">&nbsp;</p>
  {/if}

  <form>
    <input type="password" bind:value={pw} />
    <button on:click={login}>{btnText}</button>
  </form>
</div>

<style>
  header {
    position: absolute;
    left: 1.5rem;
    top: 1rem;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  p {
    margin-bottom: 6rem;
    text-align: center;
  }
  input {
    padding: 1rem;
    height: 3rem;
    border: 0;
    flex: 1;
  }
  .wrong-password {
    margin: 0;
    color: var(--jl-error-red);
  }
  form {
    width: 100%;
    max-width: 50rem;
    border-bottom: 0.5px solid var(--jl-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  button {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--jl-black);
    padding: 0 1rem;
  }

  input:focus {
    outline: none;
    background: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
    box-shadow: 0 0 0 30px white inset;
  }
</style>
