<script>
  import {slide} from 'svelte/transition';
</script>

<section>
  <div transition:slide={{duration: 300}}>
    <slot />
  </div>
</section>

<style>
  section {
    position: absolute;
    right: 0;
    width: 50%;
    max-width: 33.75rem;
    margin-top: 3.75rem;
    z-index: 1;
    background-color: white;
  }
  section:before {
    content: '';
    position: absolute;
    width: 90%;
    height: 0.0625rem;
    left: 5%;
    top: 0;
    background-color: var(--jl-light-grey);
  }
</style>
