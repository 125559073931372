<script>
  import ContentfulParser from './content/parsers/ContentParser.svelte';
  import ProductListingPage from './product/views/ProductListingPage.svelte';
  import LoginPage from './auth/LoginPage.svelte';
  import {Router, Route} from 'svelte-navigator';
  import {restrictedAccesss} from './auth/service';
  $: authed = restrictedAccesss();
</script>

<Router>
  {#if authed}
    <Route path="/category/*category/*subCategory/*productCollection" let:params>
      <ProductListingPage {params} />
    </Route>

    <Route path="/ProductDetailPage/:id" let:params>
      <ContentfulParser slug="product-detail-page" hideHeader {params} />
    </Route>

    <Route path="/content/:slug" let:params>
      <ContentfulParser {params} />
    </Route>

    <Route path="/preview/:slug" let:params>
      <ContentfulParser {params} preview={true} />
    </Route>

    <Route path="/*slug/*id" let:params>
      <ContentfulParser {params} />
    </Route>
  {:else}
    <Route path="*" let:params>
      <LoginPage {params} />
    </Route>
  {/if}
</Router>

<style>
</style>
