import RequestHandler from './requestHandler.js';

/**
 * @typedef Page
 * @property {PageDetails} details
 * @property {{type: string}[]} components
 */
/**
 * @typedef PageDetails
 * @property {string} pageType
 * @property {string | undefined} headerType
 */

const ContentService = function () {
  const apiUrl = process.env.contentApiUrl;
  const requestHandler = new RequestHandler();

  /**
   * @param {string} slug
   * @param {boolean} preview
   * @returns {Promise<Page>}
   */
  ContentService.prototype.getPageBySlug = function (slug, preview = false) {
    const query = preview === true ? '?preview=true' : '';
    return requestHandler.getJson(`${apiUrl}/${slug}${query}`).then((response) => response.data[0]);
  };

  ContentService.prototype.getMenuBySlug = function (slug) {
    return requestHandler.getJson(`${apiUrl}/menu/${slug}`).then((response) => response.data[0]);
  };

  ContentService.prototype.getCartBySlug = function (slug) {
    return requestHandler.getJson(`${apiUrl}/cart/${slug}`).then((response) => response.data[0]);
  };
};

export default ContentService;
