<script>
  import {createEventDispatcher} from 'svelte';
  import CategoryMenu from './categoryMenu/CategoryMenu.svelte';
  import FilterMenu from './filterMenu/FilterMenu.svelte';

  const dispatch = createEventDispatcher();

  export let selectedMenuType;
  export let menuRootNodes = [];
  export let isMenuVisible = false;

  function applyFilterSelection(event) {
    dispatch('apply-filter-selection', event.detail);
  }

  function closeMenu() {
    dispatch('close-menu');
  }
</script>

<nav class="mobile-nav flex-col justify-between {isMenuVisible}">
  <div class="content-wrapper">
    {#if selectedMenuType === 'category'}
      <CategoryMenu {menuRootNodes} on:close-menu={closeMenu} />
    {:else if selectedMenuType === 'filter'}
      <FilterMenu on:apply-filter-selection={applyFilterSelection} />
    {/if}
  </div>
</nav>

<svelte:head>
  {#if isMenuVisible}
    <style>
      body {
        overflow: hidden;
      }
    </style>
  {/if}
</svelte:head>

<style>
  .mobile-nav {
    height: 100vh;
    width: 100%;
    background-color: var(--jl-offwhite);
    pointer-events: auto;
    position: fixed;
    margin-top: 3.125rem;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 4rem 0 0 0;
    transform: translateY(100vh);
    transition: transform 0.3s ease-in-out;
  }

  .mobile-nav.true {
    transform: translateY(0);
  }

  .content-wrapper {
    margin-top: auto;
    transition: 0.9s;
    padding-bottom: 4rem;
    overflow-y: scroll;
  }
</style>
