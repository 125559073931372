<script>
  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  export let label = 'Text Button';

  function click() {
    dispatch('click');
  }
</script>

<button on:click={click}>{label}</button>

<style>
  button {
    border: none;
    background-color: transparent;
    padding: 0;
    font-family: var(--jl-font-family-bold);
    font-size: 0.75em;
    font-weight: 600;
    text-transform: uppercase;
  }
</style>
