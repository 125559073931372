<script>
  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  export let filter = {
    label: 'Filter',
    name: 'filter',
    options: [
      {
        value: 'a',
        label: 'A',
      },
      {
        value: 'b',
        label: 'B',
      },
      {
        value: 'c',
        label: 'C',
      },
    ],
  };
  export let selectedValues = [];

  function updateFilterSelection() {
    dispatch('update-filter-selection', {name: filter.name, values: selectedValues});
  }
</script>

<div>
  <div class="options">
    {#each filter.options as option}
      <label>
        <input
          type="checkbox"
          name={filter.name}
          bind:group={selectedValues}
          value={option.value}
          on:change={updateFilterSelection.bind(selectedValues)}
        />
        <span class="checkbox" />
        <span class="text-style-1">{option.label}</span>
      </label>
    {/each}
  </div>
</div>

<style>
  div {
    text-align: left;
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    justify-items: start;
  }

  .options > label {
    display: inline-flex;
    align-items: center;
    width: 35%;
    margin: 0.5rem 1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .options > label > span {
    line-height: 100%;
  }

  .options > label > span.checkbox {
    content: '';
    display: block;
    position: absolute;
    width: 0.875rem;
    height: 0.875rem;
    border: 0.0625rem solid var(--jl-black);
  }

  span.checkbox:after {
    content: '';
    display: hidden;
    position: absolute;
    width: 0.875rem;
    height: 0.875rem;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgOCA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAyLjcxNDI5TDMuMTgxODIgNUw3IDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPC9zdmc+Cg==')
      35% 40% no-repeat;
  }

  .options > label > span:last-of-type {
    margin-left: 1.75rem;
  }

  input[type='checkbox'] {
    accent-color: var(--jl-black);
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input[type='checkbox']:checked ~ span.checkbox {
    background-color: var(--jl-black);
  }

  input[type='checkbox']:checked ~ span.checkbox:after {
    display: block;
  }
</style>
