<script>
  export let element;
  let html;

  $: {
    html = buildElement(element);
  }

  function getElementName(element) {
    switch (element.nodeType) {
      case 'heading-1':
        return 'h4';
      case 'heading-2':
        return 'h5';
      case 'unordered-list':
        return 'ul';
      default:
        return 'p';
    }
  }

  function getElementClassList(element) {
    switch (element.nodeType) {
      case 'heading-1':
        return ['heading-4', 'mt-6'];
      case 'heading-2':
        return ['heading-5 mt-4'];
      case 'unordered-list':
        return ['text-style-4 mt-6'];
      default:
        return ['body-3'];
    }
  }

  function buildTextNode(textNode) {
    console.log(textNode);
    const value = textNode.value.replace(/\n/g, '<br	/>');
    const classList = textNode.marks
      .map((mark) => {
        switch (mark.type) {
          case 'bold':
            return 'text-bold';
          case 'italic':
            return 'text-italic';
          default:
            null;
        }
      })
      .filter((x) => x);
    if (classList.length > 0) return `<span	class="${classList.join(' ')}">${value}</span>`;
    return value;
  }

  function buildListItemNode(node) {
    return node.content
      .map((item) => {
        return `<li>${item.content.map(buildTextNode).join('')}</li>`;
      })
      .join('\n');
  }

  function buildHyperlink(node) {
    return node.content
      .map((item) => {
        return `<a href="${node.data.uri}">${buildTextNode(item)}</a>`;
      })
      .join('\n');
  }

  function buildNode(node) {
    switch (node.nodeType) {
      case 'text':
        return buildTextNode(node);
      case 'list-item':
        return buildListItemNode(node);
      case 'hyperlink':
        return buildHyperlink(node);
      default:
        return 'Unsupported	element.';
    }
  }

  function buildElement(element) {
    const elementName = getElementName(element);
    const classList = getElementClassList(element);
    return `<${elementName}	class="${classList.join(' ')}">${element.content
      .map(buildNode)
      .join('\n')}</${elementName}>`;
  }
</script>

{@html html}

<style>
</style>
