<script>
  /**
   * Inspirational Entries
   *  @type {[]}
   */
  export let inspirationalEntries;
</script>

<div class="inspirational-container h-[100vh] md:h-full">
  <div class="grid-rows-3 grid md:flex h-full inline-block">
    {#each inspirationalEntries as entry}
      <div class="image-entries-container md:h-full md:w-1/3 ">
        <img class="inspirational-entry" src={entry.imageUrl} alt={entry.overlayText} />
        <div class="overlay-container h-full w-full flex justify-between">
          <div class="overlay-text-wrapper relative justify-start">
            <h1 class={'overlay-text-' + entry.overlayTextPosition.toString().toLowerCase()}>
              {entry.overlayText.toUpperCase()}
            </h1>
          </div>
          <div
            class="{'overlay-cta-' +
              entry.overlayTextPosition.toString().toLowerCase()} h-full p-4 whitespace-nowrap flex"
          >
            <h2><a href={entry.ctaUrl}> {entry.ctaText.toUpperCase()} </a></h2>
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>

<style>
  .inspirational-container {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }

  .inspirational-entry {
    object-fit: cover;
    object-position: 50% 20%;
    height: 100%;
    width: 100%;
  }

  .image-entries-container {
    display: inline-block;
    position: relative;
  }

  .overlay-container {
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  h1,
  h2 > a {
    font-family: 'JLSemiBold', sans-serif;
    color: white;
  }

  .overlay-text-wrapper {
    letter-spacing: 2px;
    white-space: nowrap;
  }

  .overlay-text-top {
    padding: 1rem;
    position: absolute;
    top: 0;
    left: 0;
  }

  .overlay-text-bottom {
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .overlay-text-side {
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 40%) rotate(-90deg) translate(50%, 50%);
  }

  .overlay-cta-top {
    align-items: start;
  }
  .overlay-cta-bottom {
    align-items: end;
  }

  .overlay-cta-side {
    align-items: end;
  }
</style>
