<script>
  import {createEventDispatcher} from 'svelte';
  import {slide} from 'svelte/transition';

  export let parentNode;

  const dispatch = createEventDispatcher();

  function getNodeUrl(node) {
    const parentValue = parentNode.linkValue || parentNode.label;
    const nodeValue = node.linkValue || node.label;
    return `/category/${parentValue}/${nodeValue}`.replace(/ /g, '');
  }

  function getChildNodeUrl(node, childNode) {
    const childValue = childNode.linkValue || childNode.label;
    return `${getNodeUrl(node)}/${childValue}`.replace(/ /g, '');
  }
</script>

<div
  class="categories-wrapper flex justify-center"
  on:mouseleave={() => dispatch('mouse-leave')}
  in:slide={{duration: 800}}
  out:slide={{duration: 800}}
>
  {#each parentNode.children as node}
    <div class="category">
      <h2 class="heading-2">
        <a on:click={() => dispatch('click')} class="menu-link" href={getNodeUrl(node)}
          >{node.label}</a
        >
      </h2>
      <ul>
        {#each node.children as childNode}
          <li>
            <a
              on:click={() => dispatch('click')}
              class="category-name text-style-4 menu-link"
              href={getChildNodeUrl(node, childNode)}
              >{childNode.label}
            </a>
          </li>
        {/each}
      </ul>
    </div>
  {/each}
</div>

<style>
  .category-name {
    text-transform: none;
  }

  .categories-wrapper {
    padding: 0 2.5rem;
    width: 100%;
    z-index: 300;
    justify-content: start;
  }

  .category {
    text-align: left;
    margin: 6.25rem 6.25rem;
    display: inline-flex;
    flex-direction: column;
  }

  ul {
    margin-top: 0.75rem;
  }

  li {
    margin: 0.75rem 0;
  }

  h2.heading-2 {
    line-height: 100%;
  }

  h2.heading-2 > a.menu-link {
    line-height: 0;
  }
</style>
