<script>
  import MobileFooterAccordion from './MobileFooterAccordion.svelte';

  export let data;
  export let contact;

  let items = data.concat(contact);
</script>

<MobileFooterAccordion {items} />

<style>
</style>
