<script>
  import ProductCard from '../productCard/ProductCard.svelte';
  import ImagePriority from '../../../utils/imagePriority.js';

  export let products;

  function getProductCardImages(product) {
    return {
      frontImage: ImagePriority.getFrontImage(product.images),
      hoverImage: ImagePriority.getHoverImage(product.images),
    };
  }
</script>

<div class="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4">
  {#each Object.values(products) as product, i}
    <ProductCard
      id={product.productId}
      title={product.productName}
      images={getProductCardImages(product)}
      color={product.colorName ? product.colorName : product.colorFamily}
      price={product.priceSEK}
    />
  {/each}
</div>
