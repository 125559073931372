<script>
  // THIS FILE IS JUST A SANDBOX DO NOT USE FOR PRODUCTION CODE!!!

  /**
   * @typedef ParamsWithSlug
   * @property {string} slug Slug for the content resource. Should be set when page is created in Compose.
   */

  import Footer from '../../footer/Footer.svelte';
  import InteractionService from '../../services/interactionService.js';

  /**
   * @type {ParamsWithSlug}
   */
  export let params;
  export let querystring;
  export let slug;
  export let preview = false;
  export let hideHeader = false;
  $: currentSlug = slug;

  const DEFAULT_HEADER_TYPE = 'Transparent';
  let headerType = DEFAULT_HEADER_TYPE;

  export let menuOpen = false;
  function menuOpened() {
    menuOpen = true;
  }
  function menuClosed() {
    menuOpen = false;
  }

  import Header from '../../header/Header.svelte';
  import FullWidthBanner from '../FullWidthVideoBanner.svelte';
  import InspirationalBlock from '../SplitScreenX3.svelte';
  import ActivityBlock from '../ActivityBlock.svelte';
  import ProductSuggestionBlock from '../ProductSuggestionBlock.svelte';
  import ProductDetailPage from '../../product/views/ProductDetailPage.svelte';
  import ContentSplitBlock from '../ContentSplitBlock.svelte';
  import StoreList from '../../store/StoreList.svelte';

  import ContentService from '../../services/contentService.js';

  const contentService = new ContentService();
  const interactionService = new InteractionService();

  let svelteComponentMap = [];

  const _svelteComponentTable = {
    Banner: FullWidthBanner,
    InspirationalBlock: InspirationalBlock,
    ActivityBlock: ActivityBlock,
    ProductSuggestionBlock: ProductSuggestionBlock,
    PageSplitContents: ContentSplitBlock,
    StoreList: StoreList,
    ProductDetailPage: ProductDetailPage,
  };

  function resolveSvelteComponent(pageComponentType) {
    return _svelteComponentTable[pageComponentType];
  }

  function appendParametersIfNecessary(component) {
    if (component.type === 'ProductDetailPage')
      return {
        params: {
          ...params,
          querystring: $querystring,
        },
        components: component.componentsCollection.items,
      };
    return component;
  }

  $: {
    currentSlug = slug || params.slug || 'test-landing-page';
    interactionService.sendEvent('Page View', 'example@jlbeta.com', {slug: currentSlug});
    contentService
      .getPageBySlug(currentSlug, preview)
      .then((page) => {
        headerType = page.details.headerType || DEFAULT_HEADER_TYPE;
        return page.components;
      })
      .then((components) =>
        components.map((component) => [
          resolveSvelteComponent(component.type),
          appendParametersIfNecessary(component),
        ])
      )
      .then((pairs) => {
        svelteComponentMap = pairs;
      });
  }
</script>

{#if !hideHeader}
  <Header on:menuOpened={menuOpened} on:menuClosed={menuClosed} {headerType} />
{/if}
<main class={menuOpen}>
  {#each svelteComponentMap as [component, parameters]}
    <section>
      <svelte:component this={component} {...parameters} />
    </section>
  {/each}
</main>

<Footer />

<style>
  section {
    border-bottom: 0.125rem solid var(--jl-white);
  }
</style>
