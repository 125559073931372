<div class="increment-icon">
  <div class="wrapper">
    <div class="line line-1" />
    <div class="line line-2" />
  </div>
</div>

<style>
  .increment-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .line {
    width: 0.7rem;
    height: 0.07rem;
    background-color: var(--jl-black);
  }
  .increment-icon .line-2 {
    transform: rotate(90deg) translate(-1px);
  }
</style>
