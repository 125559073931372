<script>
  import priceParser from '../../utils/priceParser.js';
  import imageTransformer from '../../utils/imageTransformer.js';

  export let linkUrl;
  export let title;
  export let imageUrl;
  export let price = 6000;
  export let itemsInMobileView;
  export let itemsInDesktopView;
  export let indexItem;

  let hiddenTailwind = () => {
    let res = '';
    if (indexItem && itemsInMobileView && itemsInMobileView <= indexItem) {
      res = 'hidden';
    }
    if (
      !itemsInDesktopView ||
      (indexItem && itemsInDesktopView && itemsInDesktopView > indexItem)
    ) {
      res += ' md:contents';
    }
    if (
      indexItem &&
      itemsInMobileView &&
      itemsInDesktopView <= indexItem &&
      itemsInMobileView > indexItem
    ) {
      res += ' md:hidden';
    }
    return res;
  };
</script>

<div class={hiddenTailwind()}>
  <div>
    <a href={linkUrl}>
      <figure>
        <img class="object-cover w-full" src={imageTransformer.getPreview(imageUrl)} alt={title} />
      </figure>
    </a>
    <div class="content-wrapper">
      <div class="text-left px-8 py-4">
        <p class="text-style-2">{title}</p>
        {#if price}
          <p class="text-style-4">{priceParser(price)} SEK</p>
        {/if}
      </div>
    </div>
  </div>
</div>
