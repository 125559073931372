<script>
  export let header = 'Activities';
  export let showTitle = true;
  export let showTabs = true;
  export let categories = [];
  export let imageBlocks = [];
  export let contentPerCategory = 2;

  let selectedCategoryIndex = 0;
  $: selectedCategory = categories[selectedCategoryIndex];

  $: activityContent = imageBlocks.slice(
    selectedCategoryIndex * contentPerCategory,
    selectedCategoryIndex * contentPerCategory + contentPerCategory
  );

  function selectCategory(index) {
    selectedCategoryIndex = index;
  }
</script>

<div class="activity-wrapper flex flex-col">
  {#if showTitle || showTabs}
    <header class="px-4 header h-16 flex flex-col items-start">
      {#if !!showTitle}
        <h2 class="mb-2 uppercase tracking-widest text-lg">{header}</h2>
      {/if}
      {#if !!showTabs}
        <div class="category-area flex flex-row mb-2">
          {#each categories as category, idx}
            <div class="category-item mr-3 cursor-pointer" on:click={() => selectCategory(idx)}>
              {#if selectedCategory === category}
                <p class="text-black font-black uppercase text-sm">{category}</p>
              {/if}
              {#if selectedCategory !== category}
                <p class="text-gray-400 font-black uppercase text-sm">{category}</p>
              {/if}
            </div>
          {/each}
        </div>
      {/if}
    </header>
  {/if}
  <div
    class="image-area max-h-full grow grid grid-rows-{activityContent.length} lg:grid-rows-1 lg:grid-cols-{activityContent.length} gap-[0.125rem]"
  >
    {#each activityContent as content}
      <a href={content.ctaUrl} class="activity-content relative">
        <figure>
          <img
            alt="Activity"
            class="w-full h-full object-cover object-top "
            src={content.imageUrl}
          />
        </figure>
        <div class="gradient-overlay">
          <div class="overlay">
            <h2 class="heading-2">{content.overlayText}</h2>
            <p class="text-style-1 cta-margin">{content.ctaText}</p>
          </div>
        </div>
      </a>
    {/each}
  </div>
</div>

<style>
  .activity-content {
    display: flex;
    align-items: flex-end;
  }
  .activity-content .text-style-1 {
    color: var(--jl-white);
    letter-spacing: 0.1rem;
  }

  figure {
    overflow: hidden;
  }

  img {
    width: 100vw;
    display: block;
    transition: transform 0.4s;
  }

  .overlay {
    text-align: left;
    position: absolute;
    bottom: 5.125rem;
    left: 1.875rem;
  }

  .gradient-overlay {
    text-align: left;
    position: absolute;
    height: 100%;
    width: 100%;
    background: #01010100;
    background: -webkit-linear-gradient(top, #01010100, #01010199);
    background: -moz-linear-gradient(top, #01010100, #01010199);
    background: linear-gradient(to bottom, #01010100, #01010199);
  }

  .overlay > p {
    position: absolute;
    white-space: nowrap;
  }

  .overlay > p:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0.0625rem;
    top: 1.0625rem;
    bottom: 0;
    left: 0;
    background-color: var(--jl-white);
    transform-origin: bottom right;
    transition: transform 0.5s ease;
  }
  .activity-content:hover > .gradient-overlay .overlay p:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .activity-content:hover > figure > img {
    transform: scale(1.05);
    transform-origin: 50% 50%;
  }
  .heading-2,
  .text-style-4 {
    color: var(--jl-white);
  }

  .heading-2 {
    line-height: 200%;
  }

  .text-style-4 {
    font-weight: 600;
  }

  p.cta-margin {
    margin-left: 0.125rem;
  }
</style>
