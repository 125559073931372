<script>
  import WebFooterColumn from './WebFooterColumn.svelte';

  export let data;
</script>

{#each data as section}
  <WebFooterColumn {section} />
{/each}

<style>
</style>
