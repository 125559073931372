<script>
  // import ProductTag from './ProductTag.svelte';
  import parsePrice from '../../../utils/priceParser.js';
  import imageTransformer from '../../../utils/imageTransformer.js';

  export let id = '';
  export let title = '';
  export let images = {};
  export let price = '';
  export let currencyCode = 'SEK';
  export let tag = 'Organic cotton';
  export let color;

  let hover = false;
</script>

<div class="product-card-wrapper justify-start" {id}>
  <div class="image-wrapper">
    <a
      class="text-style-4"
      href="/ProductDetailPage/{id}?color={color}"
      on:mouseover={() => (hover = true)}
      on:mouseout={() => (hover = false)}
    >
      <figure>
        <img class="w-full" src={imageTransformer.getPreview(images.frontImage)} alt={title} />
        <img
          class="hover-content-wrapper h-auto invisible md:visible {hover}"
          src={imageTransformer.getPreview(images.hoverImage)}
          alt={title}
        />
      </figure>
    </a>
  </div>
  <div class="product-information-wrapper p-2.5 md:pt-5 md:pl-8">
    <div class="flex-col">
      <h3 class="title-text text-style-2">{title}</h3>
      <p class="price-text text-style-4 mb-4 md:mb-7">
        {parsePrice(price)}

        {currencyCode}
      </p>
    </div>
    <!--        <ProductTag {tag} />-->
  </div>
</div>

<style>
  .product-card-wrapper {
    background-color: var(--jl-white);
    padding: 0.05rem;
    display: flex;
    flex-direction: column;
  }

  .image-wrapper a {
    display: block;
    position: relative;
  }
  .product-information-wrapper {
    position: relative;
    text-align: left;
  }

  .hover-content-wrapper {
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .hover-content-wrapper.true {
    display: block;
  }

  figure {
    min-height: 12.5rem;
    aspect-ratio: 768 / 1000;
    background-color: #f0f0f0;
  }
</style>
