import RequestHandler from '../../services/requestHandler.js';

const CartService = function () {
  const apiUrl = process.env.cartApiUrl;
  const requestHandler = new RequestHandler();
  const CART_ID_KEY = 'CART_ID';

  function getCartId() {
    return window.localStorage.getItem(CART_ID_KEY);
  }

  function setCartId(cartId) {
    if (typeof cartId === 'undefined') {
      window.localStorage.removeItem(CART_ID_KEY);
    } else {
      window.localStorage.setItem(CART_ID_KEY, cartId);
    }
  }

  CartService.prototype.getCart = function () {
    const cartId = getCartId();
    if (cartId === null) {
      return Promise.resolve({
        currencyCode: 'SEK',
        totalPrice: 0,
        lineItems: [],
      });
    }
    return requestHandler
      .getJson(`${apiUrl}/${cartId}`)
      .then((cart) => {
        setCartId(cart.id);
        return cart;
      })
      .catch(console.error);
  };

  CartService.prototype.removeFromCart = function (productToRemove) {
    const cartId = getCartId();
    const body = {
      cartId: cartId,
      lineItemId: productToRemove,
    };
    return requestHandler
      .postJson(`${apiUrl}/removeFromCart`, body)
      .then((cart) => cart.data.checkoutLineItemsRemove.checkout.id)
      .catch(console.error);
  };

  CartService.prototype.addToCart = function (productToAdd) {
    const cartId = getCartId();
    const body = {
      lineItems: productToAdd,
    };
    if (cartId !== null) {
      body.cartId = cartId;
    }
    console.log(body);
    return requestHandler
      .postJson(`${apiUrl}/addToCart`, body)
      .then((cart) => {
        console.log(cart);
        setCartId(cart.id);
        return cart.lineItems;
      })
      .catch(console.error);
  };

  CartService.prototype.increaseQuantity = function (idToIncrease, amount = 1) {
    return this.addToCart({
      quantity: amount,
      variantId: idToIncrease,
    });
  };

  CartService.prototype.decreaseQuantity = function (idToDecrease, amount = -1) {
    return this.addToCart({
      quantity: amount,
      variantId: idToDecrease,
    });
  };
};

export default CartService;
