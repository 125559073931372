<script>
  import ProductService from '../../../services/productService.js';
  import ProductSuggestionCard from '../../ProductSuggestionCard.svelte';
  import ImagePriority from '../../../../utils/imagePriority.js';

  export let product = {};
  let productSuggestions = [];

  $: productSuggestions = [];

  $: {
    new ProductService()
      .getProductSuggestions(product.gender, product.productId)
      .then((response) => {
        productSuggestions = response || [];
      });
  }
</script>

<div class="my-auto">
  <p class="you-may-like-text heading-5 md:mx-24">You may also like</p>
  <div class="grid grid-cols-1 gap-0.5 md:grid-cols-4">
    {#if productSuggestions && productSuggestions[0]}
      {#each productSuggestions as product, i}
        <ProductSuggestionCard
          linkUrl="/ProductDetailPage/{product.productId}?color={product.colorName}"
          title={product.productName}
          imageUrl={ImagePriority.getPdpSimilarImages(product.images)}
          itemsInMobileView="1"
          itemsInDesktopView="4"
          indexItem={i}
          price={product.priceSEK}
        />
      {/each}
    {/if}
  </div>
</div>

<style>
  .you-may-like-text {
    text-align: left;
    padding: 1.875rem 0.938rem;
  }
</style>
