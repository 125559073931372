<script>
  import {onMount} from 'svelte';
  import Header from '../../header/Header.svelte';
  import ProductService from '../services/productService.js';
  import CartService from '../../cart/services/cartService.js';
  import ProductDetailInformation from '../components/productDetailPage/ProductDetailInformation.svelte';
  import CartMobileToaster from '../../cart/components/CartMobileToaster.svelte';
  import ProductDetailBlock from '../components/productDetailPage/contentful/ProductDetailBlock.svelte';
  import {cartOpen} from '../../cart/services/cartStore.js';
  import LoadingButton from '../../components/LoadingButton.svelte';
  import ImagePriority from '../../utils/imagePriority.js';
  import imageTransformer from '../../utils/imageTransformer.js';
  import ProductDetailImage from '../components/productDetailPage/ProductDetailImage.svelte';

  export let params;
  export let components;

  let gridGap = {default: 0.5, md: 0.5};
  let product = {};
  let selectedVariant = {};
  let skus = {};
  let disableButton = false;

  let productPrice = '';
  let cart = [];
  let cartItems = [];
  let cartItemCount = 0;
  let selectedSize = '';
  let buttonText = 'ADD TO CART';

  let description = '';
  let selectedVariantIndex;
  let selectedSizeIndex;
  let selectedColor;

  $: cartItemCount = cartItems.length;
  $: selectedVariant = setSelectedVariant;
  $: selectedSize = skus[selectedSizeIndex]?.size;
  $: showToast = false;
  $: isAddingToCart = false;
  $: disableButton ? (buttonText = 'OUT OF STOCK') : (buttonText = 'ADD TO CART');

  $: {
    selectedColor = getColorFromURL(window.location.search);
    if (params.id) getProduct();
  }

  onMount(() => {
    selectedColor = getColorFromURL(window.location.search);
  });

  function getProduct() {
    return new ProductService().getProduct(params.id).then((response) => {
      product = response;
      product.id = params.id;
      selectedVariantIndex = getSelectedColorIndex(selectedColor, product.colors);
      selectedVariant = product.colors[selectedVariantIndex];
      selectedVariant.image = ImagePriority.getPdpStartImage(selectedVariant?.images);
      productPrice = product.colors[selectedVariantIndex].skus[0].priceSEK;
      skus = product.colors[selectedVariantIndex]?.skus;
      description = product.shortDescription ? product.shortDescription : '';
      selectedSizeIndex = getSizeIndex(skus);
      selectedSize = skus[selectedSizeIndex];
      disableButton = isVariantOutOfStock(skus);
    });
  }

  function getColorFromURL(string) {
    let qs = new URLSearchParams(string);
    return qs.get('color');
  }

  function toastDismissed() {
    showToast = false;
  }

  function showToaster() {
    addToCart().then(() => (showToast = true));
  }

  function showCart() {
    addToCart().then(() => cartOpen.update(() => true));
  }

  function addToCart() {
    isAddingToCart = true;
    return new CartService()
      .addToCart({
        quantity: 1,
        variantId: btoa(product.colors[selectedVariantIndex].skus[selectedSizeIndex].shopifyId),
      })
      .then((cart) => {
        cartItems = cart;
      })
      .then(() => {
        isAddingToCart = false;
      });
  }

  function setSelectedVariant(event) {
    selectedSizeIndex = event.detail.selectedSizeIndex;
    selectedVariantIndex = event.detail.variantIndex;
    selectedSize = event.detail.selectedSize;
    selectedVariant = product.colors[selectedVariantIndex];
    selectedVariant.image = ImagePriority.getPdpStartImage(selectedVariant?.images);
    productPrice = product.colors[selectedVariantIndex].skus[0]?.priceSEK;
    skus = product.colors[selectedVariantIndex].skus;
    selectedSizeIndex === undefined ? (selectedSizeIndex = getSizeIndex(skus, selectedSize)) : '';
    disableButton = isVariantOutOfStock(skus);
  }

  function getSelectedColorIndex(selectedColor, colors) {
    let c;
    return colors.findIndex((color) => {
      color.colorName ? (c = color.colorName) : (c = color.colorFamily);
      return c.toLowerCase() === selectedColor.toLowerCase();
    });
  }

  function getSizeIndex(skus, selectedSize = 'M') {
    let index = skus.findIndex((sku) => sku.size === selectedSize && sku.stock > 0);
    if (index !== -1) return index;
    else return skus.findIndex((sku) => sku.stock > 0);
  }

  function isVariantOutOfStock(skus) {
    return !skus.find((sku) => sku.stock > 0);
  }
</script>

<Header {cartItemCount} shouldOffsetHeader="true" />
<main class="mt-[4.375rem] md:mt-0">
  <div class="text-left grid md:grid-cols-2 gap-{gridGap.default} md:gap-{gridGap.md}">
    {#if selectedSize}
      <CartMobileToaster
        showPopup={showToast}
        productTitle={product.name}
        variant={selectedVariant || []}
        size={selectedSize}
        price={productPrice}
        currencyCode={cart.currencyCode}
        on:toastDismissed={toastDismissed}
      />
    {/if}
    <ProductDetailImage image={imageTransformer.getPreview(selectedVariant?.image)} />
    <div class="product-details-wrapper table px-8 min-w-full min-h-[40rem] m-auto md:min-w-[75%]">
      <ProductDetailInformation
        on:selected-variant={setSelectedVariant}
        {selectedVariantIndex}
        {selectedSizeIndex}
        {selectedColor}
        {product}
        {productPrice}
        {skus}
        {description}
      />
      <div class="md:block hidden">
        <LoadingButton
          disabled={disableButton}
          loading={isAddingToCart}
          label={buttonText}
          on:click={showCart}
        />
      </div>
      <div class="block md:hidden mb-8">
        <LoadingButton
          disabled={disableButton}
          loading={isAddingToCart}
          label={buttonText}
          on:click={showToaster}
        />
      </div>
    </div>
  </div>
</main>
{#each components as block}
  <ProductDetailBlock params={block} {product} {selectedVariant} {gridGap} />
{/each}

<style>
  .product-details-wrapper {
    align-items: center;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    max-width: 47.125rem;
  }

  .add-to-cart:active {
    background-color: var(--jl-black);
  }
</style>
