<script>
  import StoreListItem from './StoreListItem.svelte';

  export let stores;
</script>

{#if stores}
  <div class="flex flex-wrap">
    {#each stores as store}
      <StoreListItem {store} />
    {/each}
  </div>
{/if}

<style>
  div {
    margin-top: 5rem;
  }
</style>
