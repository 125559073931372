<script>
  import {createEventDispatcher} from 'svelte';

  export let isMenuVisible;
  $: isMenuVisible;
  function handleClick() {
    isMenuVisible = !isMenuVisible;
    if (isMenuVisible) {
      dispatch('menuOpened');
    } else {
      dispatch('menuClosed');
    }
  }

  const dispatch = createEventDispatcher();
</script>

<div class="hamburger-icon {isMenuVisible}" on:click={handleClick}>
  <div class="wrapper">
    <div class="line line-1" />
    <div class="line line-2" />
    <div class="line line-3" />
  </div>
</div>

<style>
  .hamburger-icon {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 400;
  }
  .line {
    width: 25px;
    height: 2px;
    background-color: var(--jl-black);
    transition: all 200ms ease-in-out;
  }
  .line + .line {
    margin-top: 6px;
  }

  .hamburger-icon.true .line-3 {
    opacity: 0;
  }
  .hamburger-icon.true .line-1 {
    transform: rotate(-45deg) translate(-2px, 4px);
    background-color: var(--jl-black);
  }
  .hamburger-icon.true .line-2 {
    transform: rotate(45deg) translate(-2px, -4px);
    background-color: var(--jl-black);
  }
</style>
