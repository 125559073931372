<script>
  import {createEventDispatcher} from 'svelte';
  import Overlay from '../../../components/Overlay.svelte';
  import Button from '../../../components/Button.svelte';

  const dispatch = createEventDispatcher();
  const closeButtonText = 'CLOSE';
  const submitButtonText = 'EMAIL ME WHEN AVAILABLE';
  const emailLabel = 'EMAIL';
  const headline = 'THE SIZE IS NOT IN STOCK';
  const title = 'CHECK BACK SOON';
  const info = '';
  let email = '';
  let isScrollDisabled = false;
  let screenWidth = 0;

  function closePopup() {
    dispatch('close-popup');
  }

  $: if (screenWidth < 768) {
    isScrollDisabled = true;
  } else {
    isScrollDisabled = false;
  }
</script>

<svelte:window bind:innerWidth={screenWidth} />

<Overlay
  overlayTailwind="justify-center flex"
  wrapperTailwind="m-auto"
  {isScrollDisabled}
  on:close={closePopup}
>
  <section class="popup-wrapper flex h-[60vh] md:h-[70vh]">
    <button class="close-btn mt-6 mr-3 md:mr-6" on:click={closePopup}>{closeButtonText}</button>
    <div class="px-4 md:px-24 md:w-[70vh]">
      <p class="heading-5 mb-2">{headline}</p>
      <p class="text-style-1 mb-4 text-center md:text-left">{title}</p>
      <p class="body-3 text-left">{info}</p>
      <label for="email" class="hidden mt-14 mb-4 text-style-2 md:text-style-1">{emailLabel}</label>
      <input
        hidden
        type="email"
        class="email-input"
        placeholder="john.doe@gmail.com"
        bind:value={email}
      />
      <div class="hidden submit-btn-wrapper mt-32">
        <Button label={submitButtonText} />
      </div>
    </div>
  </section>
</Overlay>

<style>
  .popup-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .close-btn {
    position: absolute;
    align-self: flex-end;
    top: 0;
    right: 0;
    padding: 0.5rem;
    border: none;
    font-family: 'JLRegular';
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--jl-black);
  }

  .email-input {
    width: 100%;
    top: 2.5rem;
    border: none;
    border-bottom: 1px solid #000000;
  }

  .email-input::placeholder {
    font-family: 'Suisse by JL';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
    color: var(--jl-black);
  }

  .submit-btn-wrapper {
    width: 100%;
    height: 2.563rem;
  }
</style>
