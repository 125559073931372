<script>
  import {createEventDispatcher} from 'svelte';
  import debounce from '../../../utils/debounce.js';
  import IncrementIcon from '../../../assets/svg/IncrementIcon.svelte';
  import DecrementIcon from '../../../assets/svg/DecrementIcon.svelte';

  export let quantity;
  const dispatch = createEventDispatcher();

  const incrementDebounce = debounce((q) => dispatch('increase', {quantity: q}));
  function increment() {
    quantity++;
    incrementDebounce(quantity);
  }

  const decrementDebounce = debounce((q) => dispatch('decrease', {quantity: q}));
  function decrement() {
    if (quantity > 1) {
      quantity--;
      decrementDebounce(quantity);
    }
  }

  $: quantity = quantity < 1 ? 1 : quantity;
  $: quantity = quantity > 999 ? 999 : quantity;
</script>

<div class="quantity-content">
  <div class="decrement-wrapper" on:click={decrement}>
    <DecrementIcon disable={quantity === 1 || quantity > 999} />
  </div>
  <input class="quantity-value" bind:value={quantity} type="number" min="1" />
  <div class="increment-wrapper" on:click={increment}>
    <IncrementIcon />
  </div>
</div>

<style>
  .quantity-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    width: 5rem;
  }

  .decrement-wrapper,
  .increment-wrapper {
    display: flex;
    align-items: center;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
  }

  .quantity-value {
    font-family: 'JLSemiBold';
    font-size: 0.75rem;
    text-align: center;
    width: 3rem;
    border: none;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
</style>
