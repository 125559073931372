<script>
  import parsePrice from '../../../utils/priceParser.js';
  import ProductDetailTags from './ProductDetailTags.svelte';
  import Line from '../../../components/Line.svelte';
  import ProductDetailSizeSelector from './attributeSelectors/ProductDetailSizeSelector.svelte';
  import {createEventDispatcher} from 'svelte';
  import ProductDetailColorSelector from './attributeSelectors/ProductDetailColorSelector.svelte';

  export let product;
  export let productPrice;
  export let skus;
  export let selectedVariantIndex;
  export let selectedSizeIndex;
  export let selectedColor;
  export let description = '';

  let selectedSize;

  let tags = ['organic', 'sustainable'];

  $: description;
  const dispatch = createEventDispatcher();

  function formatDescription(description) {
    return description.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }

  function setSelectedVariant(event) {
    if (event.detail.selectedColor !== undefined) selectedColor = event.detail.selectedColor;
    if (event.detail.selectedColor !== undefined) selectedVariantIndex = event.detail.variantIndex;
    if (event.detail.selectedSize !== undefined) selectedSize = event.detail.selectedSize;
    selectedSizeIndex = event.detail.sizeIndex;
    dispatch('selected-variant', {
      selectedColor: selectedColor,
      variantIndex: selectedVariantIndex,
      selectedSize: selectedSize,
      selectedSizeIndex: selectedSizeIndex,
    });
  }
  $: productName = product.name || 'Product name';
  $: formattedProductPrice = productPrice ? parsePrice(productPrice) + ' SEK' : '– SEK';
  $: formattedDescription = formatDescription(description);
</script>

<div class="product-details-information-wrapper">
  <div class="text-area mt-8 md:mt-0">
    <div>
      <h2 class="heading-4 leading-8">{productName}</h2>
      <h2 class="text-style-3">{formattedProductPrice}</h2>
    </div>
    <div>
      <p class="description-text body-3 lg:mt-3">{@html formattedDescription}</p>
    </div>
    <div hidden>
      <ProductDetailTags {tags} />
    </div>
  </div>
  <Line tailwind="mt-8 border-t-0" />
  <div class="attributes-wrapper">
    <div class="color-wrapper">
      <ProductDetailColorSelector
        on:selected-variant={setSelectedVariant}
        productId={product.id}
        selectedIndex={selectedVariantIndex}
        colors={product.colors}
      />
    </div>
    <Line tailwind="border-t-0" />
    <div class="size-wrapper">
      <ProductDetailSizeSelector
        on:selected-sku={setSelectedVariant}
        selectedIndex={selectedSizeIndex}
        {skus}
      />
    </div>
  </div>
</div>

<style>
  .color-wrapper,
  .size-wrapper {
    padding: 1.5rem 0;
  }

  .size-wrapper {
    padding-bottom: 7rem;
  }

  .text-area > div + div {
    padding: 1rem 0 0 0;
  }

  .product-details-information-wrapper > div + div {
    padding: 0 0 2rem 0;
  }

  .description-text {
    line-height: 1.5rem;
  }
</style>
