<script>
  import {createEventDispatcher} from 'svelte';
  import ArrowDown from '../assets/svg/ArrowDown.svelte';

  const dispatch = createEventDispatcher();

  export let label = 'Dropdown';
  export let index;
  export let selectedIndex;

  $: selected = selectedIndex === index;

  function select() {
    dispatch('select', index);
  }
</script>

<button on:click={select} aria-expanded={selected} class="text-style-1">
  <span>{label}</span>
  <ArrowDown selected={!selected} />
</button>

<style>
  button {
    justify-content: space-between;
    height: 3.125rem;
    align-items: center;
    text-align: left;
    display: flex;
    border: none;
    padding: 0 0.5rem;
  }

  span {
    position: relative;
    margin: 0 0.5rem;
    display: inline-block;
  }

  [aria-expanded='true'] > span:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.0625rem;
    left: 0;
    bottom: -0.25rem;
    background-color: var(--jl-black);
  }
</style>
