<script>
  import ProductHeader from '../components/productListingPage/ProductHeader.svelte';
  import ProductService from '../services/productService.js';
  import {onMount} from 'svelte';
  import {fade} from 'svelte/transition';
  import Header from '../../header/Header.svelte';
  import ProductFeedGrid from '../components/productListingPage/ProductFeedGrid.svelte';
  import Footer from '../../footer/Footer.svelte';

  export let params = [];
  export let menuOpen = false;

  let _products;
  $: products = _products || [];
  let selectedCategory = {category: 'Home'};
  let fetchWithFilter;

  $: fetchWithFilter = Object.keys(params).length;
  $: if (fetchWithFilter) getAllProductsByFilter(params);

  function categorySelected(message) {
    selectedCategory = message.detail;
  }

  function applyFilterSelection(event) {
    console.log(event.detail);
  }

  onMount(function () {
    if (fetchWithFilter) {
      getAllProductsByFilter(params);
    } else {
      new ProductService().getAllProducts().then((response) => {
        _products = response.data;
      });
    }
  });

  function getAllProductsByFilter(params) {
    new ProductService().getAllProductsByFilter(params).then((response) => {
      _products = response.data || [];
    });
  }
</script>

<div in:fade={{duration: 500}}>
  <Header
    filterButtonVisible="true"
    on:category-selected={(category) => categorySelected(category)}
    on:menuOpened={() => (menuOpen = true)}
    on:menuClosed={() => (menuOpen = false)}
    on:apply-filter-selection={applyFilterSelection}
    shouldOffsetHeader="true"
  />
  <main class="mt-[4.375rem] md:mt-0">
    {#if _products === undefined}
      <div class="flex justify-center mt-40">
        <img class="" src="/images/animation_loading_white_bg.gif" alt="loading" />
      </div>
    {:else if products.length === 0}
      <div class="flex flex-col justify-center text-center h-[90vh] md:h-[45vh]">
        <p class="heading-4 px-5 md:pb-5">This page has no products</p>
        <p class="body-1 px-5 md:px-1">Please come back later to see what we have for you</p>
      </div>
    {/if}

    <div class="hidden">
      <ProductHeader {selectedCategory} on:apply-filter-selection={applyFilterSelection} />
    </div>
    <ProductFeedGrid {products} />
  </main>
  <Footer />
</div>

<style>
</style>
