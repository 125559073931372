const authprop = 'acv1';
export const checkPassword = (pw) => {
  if (btoa(pw) !== 'amxiZXRhcGFzc3dvcmQ=') {
    localStorage[authprop] = 'false';
    return false;
  }
  localStorage[authprop] = 'true';
  return true;
};

export function restrictedAccesss() {
  return localStorage[authprop] === 'true';
}
