<script>
  import TextButton from '../../../../components/TextButton.svelte';
  import DropdownButton from '../../../../components/DropdownButton.svelte';
  import ProductFilterGroup from '../../productFilter/ProductFilterGroup.svelte';
  import ProductHeaderFilterPanel from './ProductHeaderFilterPanel.svelte';

  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  export let filters = [
    {
      label: 'Color',
      name: 'color',
      options: [
        {value: 'black', label: 'Black'},
        {value: 'pink', label: 'Pink'},
        {value: 'yellow', label: 'Yellow'},
        {value: 'red', label: 'Red'},
        {value: 'blue', label: 'Blue'},
      ],
    },
    {
      label: 'Size',
      name: 'size',
      options: [
        {value: 'xs', label: 'Xsmall'},
        {value: 's', label: 'Small'},
        {value: 'm', label: 'Medium'},
        {value: 'l', label: 'Large'},
        {value: 'xl', label: 'Xlarge'},
        {value: 'xxl', label: 'XXLarge'},
      ],
    },
  ];

  let filterSelection = {
    color: [],
    size: [],
  };

  $: filterCount = Object.keys(filterSelection).reduce(
    (count, key) => count + filterSelection[key].length,
    0
  );

  let selectedIndex;
  function setSelectedIndex(event) {
    const index = event.detail;
    if (selectedIndex === index) return (selectedIndex = null);
    selectedIndex = index;
  }

  let filter;
  let selectedValues;

  $: if (selectedIndex) {
    filter = selectedIndex !== null ? filters[selectedIndex] : null;
    selectedValues = selectedIndex !== null ? filterSelection[filter.name] : null;
  } else {
    filter = null;
    selectedValues = null;
  }

  function updateFilterSelection(event) {
    filterSelection[event.detail.name] = event.detail.values;
  }

  function clearFilterSelection() {
    Object.keys(filterSelection).forEach((key) => {
      filterSelection[key] = [];
    });
    applyFilterSelection();
  }

  function applyFilterSelection() {
    dispatch('apply-filter-selection', filterSelection);
  }
</script>

<DropdownButton label="Color" {selectedIndex} on:select={setSelectedIndex} index="0" />
<DropdownButton label="Size" {selectedIndex} on:select={setSelectedIndex} index="1" />

{#if filter}
  <ProductHeaderFilterPanel>
    <div class="content">
      <ProductFilterGroup
        {filter}
        {selectedValues}
        on:update-filter-selection={updateFilterSelection}
      />
    </div>
    <div class="actions">
      <div>
        <TextButton label="Clear all" on:click={clearFilterSelection} />
        <TextButton label="Apply filter ({filterCount})" on:click={applyFilterSelection} />
      </div>
    </div>
  </ProductHeaderFilterPanel>
{/if}

<style>
  div.content {
    padding: 1.5rem;
  }

  div.actions {
    position: relative;
    margin-top: 2rem;
    padding: 1rem 0;
  }

  div.actions > div {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
    width: 100%;
  }

  div.actions:before {
    content: '';
    position: absolute;
    width: 90%;
    height: 0.0625rem;
    left: 5%;
    top: 0;
    background-color: var(--jl-light-grey);
  }
</style>
