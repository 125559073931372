<script>
  import {createEventDispatcher} from 'svelte';
  const dispatch = createEventDispatcher();
  setTimeout(() => {
    dispatch('done');
  }, 5000);
</script>

<div class="autoclose-circle">
  <div class="outer-shadow" />
  <div class="inner-shadow">
    <slot />
  </div>

  <div class="hold left">
    <div class="fill" />
  </div>
  <div class="hold right">
    <div class="fill" />
  </div>
</div>

<style>
  .autoclose-circle {
    width: 1.875rem;
    height: 1.875rem;
    position: relative;
  }

  .outer-shadow,
  .inner-shadow {
    z-index: 4;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
  .inner-shadow {
    top: 50%;
    left: 50%;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: -0.875rem;
    margin-top: -0.875rem;
    border-radius: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hold {
    position: absolute;
    width: 100%;
    height: 100%;
    clip: rect(0px, 1.875rem, 1.875rem, 0.91rem);
    border-radius: 100%;
    background-color: var(--jl-light-grey);
  }
  .fill {
    background-color: var(--jl-black);
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    clip: rect(0px, 0.91rem, 1.875rem, 0px);
  }

  .left .fill {
    z-index: 1;
    -webkit-animation: left 2.5s linear;
    -moz-animation: left 2.5s linear;
    animation: left 2.5s linear both;
  }

  @keyframes left {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }

  @-webkit-keyframes left {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(180deg);
    }
  }

  .right {
    z-index: 3;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .right .fill {
    z-index: 3;
    -webkit-animation: right 2.5s linear;
    -moz-animation: right 2.5s linear;
    animation: right 2.5s linear both;
    -webkit-animation-delay: 2.5s;
    -moz-animation-delay: 2.5s;
    animation-delay: 2.5s;
  }

  @keyframes right {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }

  @-webkit-keyframes right {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }
</style>
