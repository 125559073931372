<script>
  import ProductSizeUnavailablePopup from '../../../../product/components/productDetailPage/ProductSizeUnavailablePopup.svelte';
  import {createEventDispatcher} from 'svelte';
  import sizeParser from '../../../../utils/sizeParser.js';

  export let skus;
  export let selectedIndex;

  let showPopup = false;

  const dispatch = createEventDispatcher();

  function setSelectedSize(size, index) {
    dispatch('selected-sku', {selectedSize: size, sizeIndex: index});
  }

  function isNumberedSize(size) {
    return /\d/.test(size);
  }
</script>

{#if showPopup}
  <ProductSizeUnavailablePopup on:close-popup={() => (showPopup = false)} />
{/if}
<div class="items-wrapper flex">
  {#if skus.length > 0}
    {#each skus as sku, i}
      <label class="attribute-inputs">
        {#if sku.stock > 0}
          <input type="radio" name="sizes" value={i} bind:group={selectedIndex} />
          {#if selectedIndex === i}
            <span class="text-style-1">
              {isNumberedSize(sku.size) ? sku.size : sizeParser(sku.size)}
            </span>
          {:else}
            <span class="text-style-1" on:click={setSelectedSize(sku.size, i)}>
              {sku.size}
            </span>
          {/if}
        {:else}
          <span class="out-of-stock-text text-style-1" on:click={() => (showPopup = true)}>
            {sku.size}
          </span>
        {/if}
      </label>
    {/each}
  {/if}
</div>

<style>
  label {
    margin: 0 1.5rem 0 0;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    border: 0;
    transform: translateY(-0.075em);
    display: none;
    place-content: center;
  }

  input[type='radio']::before {
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  input[type='radio']:checked + span {
    border-bottom: 0.8px solid var(--jl-black);
  }

  .out-of-stock-text {
    color: var(--jl-grey);
  }

  label > span {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    cursor: pointer;
  }

  .items-wrapper {
    flex-wrap: wrap;
  }

  span {
    position: relative;
    cursor: pointer;
    padding-bottom: 1px;
  }

  span:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0.05rem;
    bottom: -0.8px;
    left: 0;
    background-color: var(--jl-black);
    transform-origin: bottom right;
    transition: transform 0.5s ease;
  }

  .out-of-stock-text:after {
    background-color: var(--jl-grey);
  }

  span:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
</style>
