import ContentService from '../../services/contentService.js';

const MenuService = function () {
  const slug = 'menu-page';
  const contentService = new ContentService();

  MenuService.prototype.getMenu = function () {
    return contentService.getMenuBySlug(slug);
  };
};

export default MenuService;
