<script>
  import QuantityStepper from './QuantityStepper.svelte';
  import parsePrice from '../../../utils/priceParser.js';
  import {createEventDispatcher} from 'svelte';
  import CrossIcon from '../../../assets/svg/CrossIcon.svelte';
  import sizeParser from '../../../utils/sizeParser.js';

  export let id;
  export let variantId;
  export let feedbackMessage = '';
  export let currencyCode;
  export let title;
  export let price;
  export let quantity = 1;
  export let image;
  export let selectedColor;
  export let selectedSize;
  export let feedbackText = '';
  export let shownInToast = false;
  let totalPrice = 0;

  $: totalPrice = price * quantity;

  const dispatch = createEventDispatcher();

  function removeItem() {
    dispatch('remove', {id});
  }

  function increaseQuantityOfItem(event) {
    const quantityToAdd = event.detail.quantity - quantity;
    dispatch('increase-quantity', {id: variantId, quantity: quantityToAdd});
  }

  function decreaseQuantityOfItem(event) {
    const quantityToRemove = event.detail.quantity - quantity;
    dispatch('decrease-quantity', {id: variantId, quantity: quantityToRemove});
  }

  $: quantity;
</script>

<div class="cartitem-wrapper">
  <div class="image-wrapper">
    <figure>
      <img src={image} alt={title} />
    </figure>
  </div>
  <div class="justify-end flex flex-col h-full w-full">
    <div class="product-information-wrapper">
      <div class="flex-col justify-start">
        <div class="top-row">
          <div class="item-texts">
            <h3 class="title text-style-2">{title}</h3>
            <h3 class="feedback-text text-style-2">{feedbackText}</h3>
          </div>
          {#if !shownInToast}
            <div class="close-button" on:click={removeItem}>
              <CrossIcon />
            </div>
          {/if}
        </div>
        <div class="item-attributes">
          <h3 class="text-style-2">{sizeParser(selectedSize)}</h3>
          <h3 class="text-style-2">{selectedColor}</h3>
        </div>
        <div class="price-text text-style-5 visible md:invisible">
          {parsePrice(totalPrice) || ''}
          {currencyCode || 'SEK'}
        </div>
      </div>
      {#if !shownInToast}
        <div class="quantities-wrapper">
          <QuantityStepper
            {quantity}
            on:increase={increaseQuantityOfItem}
            on:decrease={decreaseQuantityOfItem}
          />
          <div class="price-text text-style-4 invisible md:visible">
            {parsePrice(totalPrice) || ''}
            {currencyCode || ''}
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .cartitem-wrapper {
    display: flex;
    height: 9rem;
    border-color: var(--jl-light-grey);
    border-bottom-width: 0.5px;
    padding: 1rem 0 1rem 0;
    align-items: center;
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    width: 20%;
  }

  .image-wrapper figure {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .image-wrapper > figure > img {
    height: 75%;
    width: 75%;
    object-fit: contain;
  }

  .product-information-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;
    padding-left: 1rem;
    justify-content: space-between;
  }

  .top-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .close-button {
    cursor: pointer;
  }

  .item-texts {
    display: flex;
    flex-wrap: nowrap;
  }

  .feedback-text {
    color: var(--jl-grey);
  }

  h3 + h3 {
    text-indent: 0.5rem;
  }
  .item-attributes {
    display: flex;
    flex-wrap: nowrap;
  }

  .product-information-wrapper .quantities-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
</style>
