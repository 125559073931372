<script>
  export let store;
</script>

<div class="w-full md:w-1/4 my-[2rem] md:my-[6rem]">
  <div>
    <h6 class="heading-7">{store.country} – {store.city}</h6>
    <p class="text-style-1">{store.address}</p>
    <p class="body-3">{store.email}<br />{store.phoneNumber}</p>
  </div>
</div>

<style>
  div {
    display: flex;
    justify-content: center;
  }

  div > div {
    display: flex;
    flex-direction: column;
  }

  h6 {
    white-space: nowrap;
  }

  .text-style-1 {
    color: var(--jl-middle-grey);
    margin: 0.5rem 0;
  }

  .body-3 {
    line-height: 200%;
  }
</style>
