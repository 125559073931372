<script>
  import MobileFooter from './MobileFooter/MobileFooter.svelte';
  import WebFooter from './WebFooter/WebFooter.svelte';
  import CookieConsentPopup from '../components/CookieConsentPopup.svelte';

  const contact = {
    heading: 'Contact',
    links: [
      {label: 'contact@jlindeberg.com', linkURL: 'mailto:contact@jlindeberg.com'},
      {label: '08-509 245 32', linkURL: 'tel:+46850924532'},
      {label: 'kl 09-16'},
    ],
  };

  const data = [
    {
      heading: 'J.Lindeberg',
      links: [
        {label: 'About us', linkURL: '/about-us'},
        {label: 'Sustainability', linkURL: '/content-missing'},
        {label: 'Press', linkURL: '/content-missing'},
        {label: 'Contact us', linkURL: '/content-missing'},
        {label: 'Stores', linkURL: '/stores'},
        {label: 'Career', linkURL: '/career'},
      ],
    },
    {
      heading: 'Customer Service',
      links: [
        {label: 'Accessibility', linkURL: '/content-missing'},
        {label: 'FAQ', linkURL: '/faq-page'},
        {label: 'Shipping', linkURL: '/shipping'},
        {label: 'Returns & Exchanges', linkURL: '/returns-and-exchange'},
      ],
    },
    {
      heading: 'Policies',
      links: [
        {label: 'Policies (Terms & Conditions)', linkURL: '/terms-and-conditions'},
        {label: 'Privacy Policy', linkURL: '/privacy-policy'},
        {label: 'Terms of Sale', linkURL: '/terms-of-sale'},
      ],
    },
    {
      heading: 'Social',
      links: [
        {
          label: 'Facebook',
          icon: 'facebook',
          linkURL: 'https://www.facebook.com/jlindebergofficial',
        },
        {
          label: 'Instagram',
          icon: 'instagram',
          linkURL: 'https://www.instagram.com/jlindebergofficial/',
        },
        {
          label: 'LinkedIn',
          icon: 'linkedin',
          linkURL: 'https://www.linkedin.com/company/j-lindeberg/',
        },
      ],
    },
  ];
</script>

<CookieConsentPopup />
<footer class="mb-2 md:mb-0">
  <div class="md:hidden">
    <MobileFooter {data} {contact} />
  </div>
  <div class="hidden md:flex justify-between lg:w-2/3 xl:w-1/2">
    <WebFooter {data} />
  </div>

  <div class="flex info-bar justify-start md:justify-between text-left pb-16 md:pb-0  ">
    <span class="body-3"
      >Copyright © 2000–2022 J.Lindeberg. All rights reserved. <br class="md:hidden" />
      <span class="md:pl-6">Orgnr 5565337085</span></span
    >
    <span class="body-3 hidden md:inline-block">
      {#each contact.links as link}
        {#if link.linkURL}
          <a class="contact" href={link.linkURL}>{link.label}</a>
        {:else}
          <span class="contact">{link.label}</span>
        {/if}
      {/each}
    </span>
  </div>
</footer>

<style>
  .info-bar {
    margin-top: 3rem;
    color: var(--jl-middle-grey);
  }

  .info-bar > .body-3 {
    color: var(--jl-middle-grey);
    line-height: 2.5em;
  }

  a.contact:not(:first-of-type),
  span.contact {
    margin-left: 1rem;
  }

  .text-left {
    text-align: left;
  }

  span.body-3 {
    font-size: 0.75em;
  }
</style>
