<script>
  import {onMount} from 'svelte';
  import Overlay from './Overlay.svelte';

  const consentPropertyName = 'jl_user_cookie_consent';
  let isConsentAccepted;
  const acceptButtonText = 'ACCEPT & CLOSE';
  const popupMessageText =
    'This website uses cookies in order to offer you the most relevant information. Please accept cookies for optimal performance.';

  const cookieStorage = {
    getItem: (key) => {
      const cookies = document.cookie
        .split(';')
        .map((cookie) => cookie.split('='))
        .reduce((acc, [key, value]) => ({...acc, [key.trim()]: value}), {});

      return cookies[key];
    },

    setItem: (key, value) => {
      document.cookie = `${key}=${value}`;
    },
  };

  const showPopup = () => {
    if (!cookieStorage.getItem(consentPropertyName)) {
      isConsentAccepted = false;
    }
  };
  const saveToStorage = () => {
    cookieStorage.setItem(consentPropertyName, true);
    isConsentAccepted = true;
  };

  onMount(function () {
    showPopup();
  });
</script>

{#if isConsentAccepted === false}
  <Overlay overlayTailwind="justify-center flex" wrapperTailwind="m-auto max-w-[40rem]">
    <section class="p-6">
      <p class="body-3">{popupMessageText}</p>
      <div class="mt-12 mx-auto md:w-[50%]">
        <button on:click={saveToStorage}>{acceptButtonText}</button>
      </div>
    </section>
  </Overlay>
{/if}

<style>
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--jl-black);
    width: 100%;
    min-height: 3.2rem;
    padding: 1rem;
    cursor: pointer;
    color: var(--jl-text-white);
  }
</style>
