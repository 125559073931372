<script>
  import CartItem from '../components/cartItem/CartItem.svelte';
  import Checkout from '../components/checkout/Checkout.svelte';
  import {onMount} from 'svelte';
  import CartService from '../services/cartService.js';
  import ContentfulCartParser from '../../content/parsers/CartContentParser.svelte';
  import Overlay from '../../components/Overlay.svelte';
  import CheckoutDetails from '../components/checkout/CheckoutDetails.svelte';
  import {cartOpen} from '../services/cartStore';
  import {createEventDispatcher} from 'svelte';

  const cartService = new CartService();
  let dispatch = createEventDispatcher();
  let cart = [];
  let cartItems = [];
  let currencyCode;
  let checkoutUrl;
  let totalPrice;
  let titleText = 'CART';
  let closeText = 'CLOSE';
  let emptyText = 'YOU HAVE NOTHING IN THE CART';
  let isScrollDisabled = false;
  let screenWidth = 0;

  onMount(function () {
    return new getCart();
  });

  function getCart() {
    new CartService()
      .getCart()
      .then((response) => {
        cart = response;
        cart.totalPrice = cart.totalPrice || 0;
        cartItems = cart.lineItems || [];
        currencyCode = cart.currencyCode;
        checkoutUrl = cart.checkoutUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function closeCart(e) {
    e.preventDefault();
    cartOpen.update(() => false);
  }

  function onRemoveItemFromCart(event) {
    cartService
      .removeFromCart(event.detail.id)
      .then(() => (cartItems = cartItems.filter((item) => item.id !== event.detail.id)))
      .then((cartItems) => dispatch('item-removed', {cartItems: cartItems}));
  }
  function increaseItemQuantity(event) {
    cartService
      .increaseQuantity(event.detail.id, event.detail.quantity)
      .then((items) => (cartItems = items));
  }
  function decreaseItemQuantity(event) {
    cartService
      .decreaseQuantity(event.detail.id, event.detail.quantity)
      .then((items) => (cartItems = items));
  }

  $: if ($cartOpen) new getCart();
  $: totalPrice = cartItems.reduce(
    (agg, item) => agg + parseInt(item.variant?.price) * item.quantity,
    0
  );
  $: if (screenWidth < 768) {
    isScrollDisabled = true;
  } else {
    isScrollDisabled = false;
  }

  function closePopup() {
    dispatch('close-popup');
  }
</script>

<svelte:window bind:innerWidth={screenWidth} />

<section class="cart">
  {#if $cartOpen}
    <Overlay
      overlayTailwind="justify-end"
      verticalEdge="bottom"
      {isScrollDisabled}
      on:close={closePopup}
    >
      <div class="cart-wrapper w-screen md:w-[50vw] p-5 md:p-8">
        <div class="top-area-wrapper">
          <header class="header-wrapper">
            <h5 class="heading-5">{titleText}</h5>
            <button on:click={closeCart} class="close-btn invisible md:visible">
              {closeText}
            </button>
          </header>
          <div class="top-slot-wrapper">
            <ContentfulCartParser position="top" />
          </div>
          <div class="static-top">
            {#if cartItems.length > 0}
              <div class="cart-items-wrapper">
                {#each cartItems as cartItem, i}
                  <CartItem
                    on:remove={onRemoveItemFromCart}
                    on:increase-quantity={increaseItemQuantity}
                    on:decrease-quantity={decreaseItemQuantity}
                    {currencyCode}
                    id={cartItem.id}
                    variantId={cartItem.variant?.id}
                    title={cartItem.title}
                    price={cartItem.variant?.price}
                    quantity={cartItem.quantity}
                    image={cartItem.variant?.image?.src ? cartItem.variant.image.src : ''}
                    selectedColor={cartItem.variant?.selectedOptions[0].value}
                    selectedSize={cartItem.variant?.selectedOptions[1].value}
                  />
                {/each}
              </div>
            {:else}
              <div class="empty-cart-wrapper">
                <span class="text-style-2 whitespace-nowrap">{emptyText}</span>
              </div>
            {/if}
            <div class="visible md:hidden">
              <CheckoutDetails numberOfItems={cartItems.length} {totalPrice} {currencyCode} />
            </div>
          </div>
          <div class="middle-slot-wrapper">
            <ContentfulCartParser position="bottom" />
          </div>
        </div>
        <div class="bottom-area-wrapper">
          <div class="checkout-wrapper">
            <Checkout numberOfItems={cartItems.length} {totalPrice} {currencyCode} {checkoutUrl} />
          </div>
        </div>
      </div>
    </Overlay>
  {/if}
</section>

<style>
  .cart {
    overflow-y: scroll;
  }
  .header-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .cart-wrapper {
    height: 100vh;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .top-area-wrapper {
    display: flex;
    flex-direction: column;
  }

  .static-top {
    display: flex;
    flex-direction: column;
    max-height: 65vh;
    justify-content: start;
    overflow-y: auto;
    overscroll-behavior: contain;
  }

  .close-btn {
    font-family: 'JLSemiBold', sans-serif;
    font-size: 0.75rem;
    color: var(--jl-black);
    cursor: pointer;
    border: 0;
    background-color: transparent;
  }

  .cart-items-wrapper {
    display: block;
    flex-direction: column;
  }

  .empty-cart-wrapper {
    display: flex;
    justify-content: center;
    padding: 5rem;
  }

  .top-slot-wrapper,
  .middle-slot-wrapper {
    display: block;
  }

  .bottom-area-wrapper {
    display: block;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
