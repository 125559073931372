<script>
  import Button from '../../../components/Button.svelte';
  import CrossIcon from '../../../assets/svg/CrossIcon.svelte';
  import {cartOpen} from '../../services/cartStore';

  export let isCartEmpty = false;
  export let checkoutUrl;
  let buttonText = 'checkout';

  $: isCartEmpty ? (buttonText = 'checkout') : (buttonText = 'checkout');
</script>

<div class="checkout-area-wrapper">
  <div
    class="close-wrapper w-1/5 md:invisible md:w-0"
    on:click={() => cartOpen.update(() => false)}
  >
    <CrossIcon width="15" height="15" />
  </div>
  <Button disabled={isCartEmpty} label={buttonText} href={checkoutUrl} />
</div>

<style>
  .checkout-area-wrapper {
    width: 100%;
    justify-content: start;
    display: flex;
  }

  .close-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
