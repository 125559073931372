<script>
  import Rectangle from '../../../assets/svg/Rectangle.svelte';

  export let tags;

  const toCapitalCase = (string) => {
    let words = string.toLowerCase().split(' ');
    for (var i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
    }
    return words.join(' ');
  };
</script>

<div class="tags-wrapper flex mini-attribute-bold items-center pt-2">
  {#each tags as tag, i}
    {#if i > 0}
      <div class="mx-1.5"><Rectangle /></div>
    {/if}
    <p class="tag">{toCapitalCase(tag)}</p>
  {/each}
</div>

<style>
  .mini-attribute-bold {
    font-size: 0.75rem;
    font-family: 'JLBold';
    line-height: 0.9rem;
  }
</style>
