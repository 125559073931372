<script>
  import CategoryMenuAccordionItem from './CategoryMenuAccordionItem.svelte';
  import {createEventDispatcher} from 'svelte';

  export let parentNode;

  const dispatch = createEventDispatcher();

  let selectedIndex = null;

  function setSelected(event) {
    const index = event.detail;
    if (selectedIndex === index) {
      selectedIndex = undefined;
      return;
    }
    selectedIndex = index;
  }

  function closeMenu() {
    dispatch('close-menu');
  }
</script>

<div class="flex-col w-full">
  {#each parentNode.children as node, nodeIndex}
    <CategoryMenuAccordionItem
      {parentNode}
      {node}
      {nodeIndex}
      {selectedIndex}
      on:selected={setSelected}
      on:close-menu={closeMenu}
    />
  {/each}
</div>
