<script>
  export let tailwind = '';
</script>

<p class="text-line {tailwind}" />

<style>
  .text-line {
    border: 0.03125rem solid #e0e0e0;
  }
</style>
