<script>
  import RichText from './components/RichTextBlock.svelte';
  import ScrollArrow from '../assets/svg/ScrollArrow.svelte';

  export let isTextScrollable;
  export let textContentList;
  let elementScrollTop = 0;
</script>

<div
  class={isTextScrollable ? 'scrollable-text' : ''}
  on:scroll={(ev) => (elementScrollTop = ev.target.scrollTop)}
>
  {#if isTextScrollable}
    <div class={elementScrollTop === 0 ? 'scroll visible' : 'invisible'}>
      <p class="scroll-text">scroll</p>
      <p class="scroll-text">
        <ScrollArrow />
      </p>
    </div>
  {/if}
  <div class="max-w-[28.125rem]">
    <RichText json={textContentList} />
  </div>
</div>

<style>
  .scrollable-text {
    height: 40rem;
    width: 100%;
    padding: 5rem;
    overflow: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scrollable-text::-webkit-scrollbar {
    display: none;
  }

  .scroll {
    position: relative;
    display: flex;
    flex-direction: column;
    top: 27rem;
  }

  .scroll-text {
    margin: 0 auto;
    color: var(--jl-black);
    font-family: 'JLRegular';
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
</style>
