import RequestHandler from '../../services/requestHandler.js';

const ProductService = function () {
  const apiUrl = process.env.productApiUrl;
  const requestHandler = new RequestHandler();

  ProductService.prototype.getAllProducts = function () {
    return requestHandler.getJson(`${apiUrl}/`);
  };

  ProductService.prototype.getAllProductsByFilter = function (filter) {
    return requestHandler.getJson(`${apiUrl}/category/${filterUrl(filter)}`);
  };

  ProductService.prototype.getProduct = function (productId) {
    return requestHandler.getJson(`${apiUrl}/ProductDetailPage/${productId}`);
  };

  ProductService.prototype.getProductSuggestions = function (gender, productId) {
    return requestHandler.getJson(`${apiUrl}/productSuggestions/${gender}/${productId}`);
  };

  function filterUrl(args) {
    let url = '';
    console.log(args);
    let length = Object.keys(args).length;
    Object.entries(args).forEach(([, value], index) => {
      if (value !== null) {
        url = url.concat(value);
        if (length > index) {
          url = url.concat('/');
        }
      }
    });
    return url;
  }
};

export default ProductService;
