<script>
  export let label;
  export let disabled = false;
  export let href = '';
</script>

<div class="w-4/5 md:w-full">
  <a class="text-style-1 {disabled}" {href}>{label}</a>
</div>

<style>
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--jl-black);
    color: var(--jl-white);
    width: 100%;
    min-height: 3.2rem;
    padding: 1rem;
    cursor: pointer;
  }

  a.true {
    background-color: var(--jl-white);
    color: var(--jl-middle-grey);
    cursor: default;
    pointer-events: none;
    border: 0.1rem solid;
    border-color: var(--jl-middle-grey);
  }

  a.false {
    border: none;
  }

  a:hover {
    text-decoration: none;
  }
</style>
