<script>
  export let height = '6';
  export let width = '8.5';
  export let selected = true;
  import {slide} from 'svelte/transition';
</script>

<svg
  class={selected}
  {height}
  viewBox="0 0 13 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  transition:slide={{duration: 300}}
>
  <path
    d="M12 1L6.66667 6.33333L1.33333 1"
    stroke="black"
    stroke-width="1.75"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<style>
  svg {
    transition: transform 0.3s ease;
  }

  .true {
    transform: rotate(0deg);
  }

  .false {
    transform: rotate(-180deg);
  }
</style>
