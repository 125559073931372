<script>
  import RichText from './components/RichTextBlock.svelte';
  import ScrollableTextSection from './ScrollableTextSection.svelte';

  export let image;
  export let imagePosition;
  export let isTextScrollable;
  export let textContentList;
  export let imageHeight;
</script>

<div class="grid grid-cols-1 md:grid-cols-2">
  <figure class={imagePosition.toLowerCase() === 'right' ? 'md:order-1' : ''}>
    <img src={image} alt="missing page logo" style="height: {imageHeight}vh;" />
  </figure>
  <section class="text">
    <div class="visible md:hidden">
      <RichText json={textContentList} />
    </div>
    <div class="hidden md:block">
      <ScrollableTextSection {isTextScrollable} {textContentList} />
    </div>
  </section>
</div>

<style>
  section,
  figure {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  figure > img {
    width: 100%;
    object-fit: cover;
  }

  section.text {
    padding: 1.25rem;
    text-align: left;
    overflow: hidden;
  }
</style>
