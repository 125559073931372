<script>
  import ProductHeaderFilterMenu from './productHeaderFilter/ProductHeaderFilterMenu.svelte';

  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  export let selectedCategory = {};

  let subcategories = ['Vests', 'Shell', 'Down', 'Fleece'];

  function applyFilterSelection(event) {
    dispatch('apply-filter-selection', event.detail);
  }
</script>

<div class="pr-6 pl-6 product-header-menu invisible">
  <div class="product-header flex items-center">
    <div class="product-sort flex align-middle items-center">
      <!--
        {#each Object.entries(selectedCategory) as [key, value], index}
          {#if Object.entries(selectedCategory).length - 1 === index}
            <p class="active">{value}</p>
          {:else}
            <p>{value}</p>
          {/if}
        {/each}
      </div> -->
    </div>

    {#if Object.entries(selectedCategory).length !== 0}
      <div class="subcategory-area flex hidden lg:invisible lg:space-between mt-6 mb-6">
        <ul class="flex w-3/4 items-center">
          {#each subcategories as subcategory}
            <li class="subcategory-link px-2">
              <a href="/#">{subcategory}</a>
            </li>
          {/each}
        </ul>
      </div>
    {:else}
      <div class="h-14 mt-6" />
    {/if}
    <div class="product-filter hidden md:flex align-middle ml-2" />
  </div>
  <!-- set to md:flex for filter menu to appear-->
  <div hidden class="hidden menu">
    <ProductHeaderFilterMenu on:apply-filter-selection={applyFilterSelection} />
  </div>
</div>

<style>
  div.product-header-menu {
    height: 4.375rem;
    display: flex;
    align-items: center;
  }
  .menu {
    margin-left: auto;
    justify-content: flex-end;
  }
  .subcategory-link a {
    font-size: 0.75rem;
    font-family: 'JLSemiBold';
    color: var(--jl-grey);
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
  .subcategory-link a:hover {
    text-decoration: none;
    color: var(--jl-black);
  }
  .product-filter {
    text-align: left;
  }
</style>
