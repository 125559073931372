<script>
  import {createEventDispatcher} from 'svelte';
  export let loading = false;
  export let disabled = false;
  export let label = '';
  const dispatch = createEventDispatcher();

  $: disableButton = loading || disabled;
</script>

<button
  disabled={disableButton}
  class="{disabled} add-to-cart visible md:hidden"
  on:click={() => dispatch('click')}
>
  {#if loading}
    <img src="/images/animation_loading.gif" alt="loading" />
  {:else}
    <p class="text-style-1">{label}</p>
  {/if}
</button>

<style>
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--jl-black);
    width: 100%;
    min-height: 3.2rem;
    padding: 1rem;
    cursor: pointer;
  }

  button.false {
    color: var(--jl-text-white);
  }

  button.true {
    background-color: var(--jl-white);
    border-color: var(--jl-middle-grey);
    cursor: default;
  }

  button > p {
    color: var(--jl-text-white);
  }

  button.true > p {
    color: var(--jl-middle-grey);
  }

  button > img {
    height: 1rem;
  }
</style>
