<script>
  import {createEventDispatcher} from 'svelte';

  export let colors = [];
  export let productId = '';
  export let selectedIndex;

  const dispatch = createEventDispatcher();

  function setSelectedVariant(selectedColor) {
    selectedIndex = colors.findIndex((color) => {
      let c;
      color.colorName ? (c = color.colorName) : (c = color.colorFamily);
      return c.toLowerCase() === selectedColor.toLowerCase();
    });
    history.replaceState({}, null, `/ProductDetailPage/${productId}?color=${selectedColor}`);

    dispatch('selected-variant', {
      selectedColor: selectedColor,
      variantIndex: selectedIndex,
      selectedSizeIndex: undefined,
    });
  }

  function getAvailableColors(colors) {
    if (typeof colors === 'undefined') return [];
    return colors.filter(hasProductionStatus).map(getColorName);
  }

  function hasProductionStatus(color) {
    return color.status === 'production';
  }

  function getColorName(color) {
    if (typeof color.colorName !== 'undefined') return color.colorName;
    if (typeof color.colorFamily !== 'undefined') return capitalize(color.colorFamily);
    return undefined;
  }

  function capitalize(string) {
    return string[0].toUpperCase() + string.substring(1);
  }
</script>

<div class="items-wrapper flex flex-wrap ">
  {#each getAvailableColors(colors) as color, i}
    <label class="attribute-inputs" on:click={setSelectedVariant(color)}>
      <input type="radio" name="colors" checked={selectedIndex === i} value={color} />
      <span class="text-style-4">{color}</span>
    </label>
  {/each}
</div>

<style>
  label {
    display: inline-block;
    position: relative;
    margin: 0 1.5rem 0 0;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    border: 0;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  input[type='radio']:checked + span {
    border-bottom: 0.8px solid var(--jl-black);
  }

  span {
    position: relative;
    cursor: pointer;
    padding-bottom: 1px;
  }

  span:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0.05rem;
    bottom: -0.8px;
    left: 0;
    background-color: var(--jl-black);
    transform-origin: bottom right;
    transition: transform 0.5s ease;
  }

  span:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
</style>
