<script>
  export let height = '10';
  export let width = '10';
</script>

<svg {width} {height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M9.76502 1.46668C10.0228 1.20894 10.0228 0.791061 9.76502 0.533323C9.50728 0.275584 9.0894 0.275584 8.83166 0.533323L9.76502 1.46668ZM0.535182 8.82981C0.277444 9.08755 0.277444 9.50542 0.535182 9.76316C0.792921 10.0209 1.2108 10.0209 1.46854 9.76316L0.535182 8.82981ZM8.83166 0.533323L0.535182 8.82981L1.46854 9.76316L9.76502 1.46668L8.83166 0.533323Z"
    fill="#3A3A3A"
  />
  <path
    d="M8.82971 9.76355C9.08745 10.0213 9.50533 10.0213 9.76306 9.76355C10.0208 9.50581 10.0208 9.08794 9.76306 8.8302L8.82971 9.76355ZM1.46658 0.533714C1.20884 0.275975 0.790967 0.275975 0.533228 0.533714C0.27549 0.791452 0.27549 1.20933 0.533229 1.46707L1.46658 0.533714ZM9.76306 8.8302L1.46658 0.533714L0.533229 1.46707L8.82971 9.76355L9.76306 8.8302Z"
    fill="#3A3A3A"
  />
</svg>
