<script>
  import ImagePriority from '../../../../utils/imagePriority.js';
  import imageTransformer from '../../../../utils/imageTransformer.js';

  export let props;
  export let selectedVariant;

  let imagePlaceholderText = 'product-preview';
  let imageUrl = '';

  $: getImageUrl(selectedVariant);

  function getImageUrl(productVariant) {
    let imageListLength = productVariant.images?.length;
    if (productVariant && imageListLength > 0) {
      imageUrl =
        imageListLength > props.imageIndex
          ? ImagePriority.getAscendingOrder(productVariant.images, props.imageIndex)
          : productVariant.images[Math.floor(Math.random() * imageListLength)].resourcePath;
    }
  }
</script>

<figure>
  <img class="w-full" src={imageTransformer.getPreview(imageUrl)} alt={imagePlaceholderText} />
</figure>

<style>
  figure {
    display: block;
    background-color: #f0f0f0;
    aspect-ratio: 768 / 1000;
  }
  img {
    margin-right: auto;
    margin-left: auto;
  }
</style>
