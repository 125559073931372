import PrecacheHandler from './precacheHandler.js';

const RequestHandler = function () {
  const options = {
    method: 'get',
    mode: 'cors',
  };

  function _fetch(url, options) {
    return fetch(url, options).then((response) => {
      if (response.ok === false) _throwResponseError(response);
      return response;
    });
  }

  function _throwResponseError(response) {
    const message = _makeResponseErrorMessage(response);
    throw new Error(message);
  }

  function _makeResponseErrorMessage(response) {
    return `${response.status}: ${response.statusText}`;
  }

  /**
   * @param {string} url
   * @returns {Promise<Response>}
   */
  RequestHandler.prototype.get = function (url) {
    return _fetch(url, options);
  };

  /**
   * @param {string} url
   * @returns {Promise<any>}
   */
  RequestHandler.prototype.getJson = function (url) {
    if (PrecacheHandler.hasCacheForResourceUrl(url))
      return PrecacheHandler.getJsonCacheForResourceUrl(url);

    return this.get(url).then((response) => response.json());
  };

  /**
   * @param {string} url
   * @returns {Promise<any>}
   */
  RequestHandler.prototype.post = function (url, body, headers) {
    return _fetch(url, {
      ...options,
      method: 'post',
      headers: headers,
      body: body,
    });
  };

  /**
   * @param {string} url
   * @param {{}|any} body
   * @param {{}|any}headers
   * @returns {Promise<any>}
   */
  RequestHandler.prototype.postJson = function (url, body, headers = {}) {
    return this.post(url, JSON.stringify(body), {
      ...headers,
      'Content-Type': 'application/json',
    }).then((response) => response.json());
  };
};

export default RequestHandler;
