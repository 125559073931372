<script>
  export let section;
</script>

<div class="pr-4">
  <h6 class="text-style-1">{section.heading}</h6>
  <ul class="text-style-4">
    {#each section.links as link}
      {#if link.icon}
        <li>
          <i class="icon {link.icon}" /><a href={link.linkURL} target="_blank">{link.label}</a>
        </li>
      {:else}
        <li>
          {#if link.linkURL}
            <a href={link.linkURL}>{link.label}</a>
          {:else}
            <span>{link.label}</span>
          {/if}
        </li>
      {/if}
    {/each}
  </ul>
</div>

<style>
  div {
    text-align: left;
  }

  h6 {
    margin-bottom: 1.5rem;
  }

  li {
    margin: 0.9375rem 0;
  }

  span {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
</style>
