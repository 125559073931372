<script>
  import RichTextElement from './RichTextElement.svelte';

  export let json;
</script>

{#if json.nodeType === 'document'}
  {#each json.content as element}
    <RichTextElement {element} />
  {/each}
{:else}
  <p>Invalid rich text payload.</p>
{/if}

<style>
</style>
