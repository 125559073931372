<script>
  import parsePrice from '../../../utils/priceParser.js';

  export let numberOfItems;
  export let totalPrice;
  export let currencyCode;
</script>

<div class="checkout-details">
  <div class="product-details">
    {#if numberOfItems > 1}
      <span class="body-3">{numberOfItems} products</span>
    {:else}
      <span class="body-3">{numberOfItems} product</span>
    {/if}
    <span class="body-3">{parsePrice(totalPrice)} {currencyCode}</span>
  </div>
  <div class="shipping-details">
    <span class="body-3">Shipping</span>
    <span class="body-3">Calculated at checkout</span>
  </div>
</div>

<style>
  span {
    color: var(--jl-middle-grey);
  }

  .checkout-details {
    display: flex;
    flex-direction: column;
    color: var(--jl-middle-grey);
    border-color: var(--jl-light-grey);
    border-bottom-width: 0.5px;
  }

  .checkout-details > div + div {
    padding: 0.5rem 0 0.5rem 0;
  }

  .checkout-details > div > h2 {
    font-family: 'JLRegular';
    font-size: 0.7rem;
    color: var(--jl-grey);
  }

  .product-details {
    display: flex;
    justify-content: space-between;
  }

  .shipping-details {
    display: flex;
    justify-content: space-between;
  }
</style>
