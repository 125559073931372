const frontImagePriority = ['005', '001'];
const hoverImagePriority = ['006', '001', '002'];
const pdpStartImagePriority = ['003', '001', '002'];
const pdpAccordianImagePriority = ['001', '002', '003'];
const cartImagePriority = ['001', '003'];
const pdpParallexPriority = ['004', '005', '007', '008', '006', '002'];
const pdpSimilarPriority = ['003', '001'];

const ImagePriority = () => {};

ImagePriority.getFrontImage = function (images = [{}]) {
  return sortByPriority(images, frontImagePriority)[0]?.resourcePath;
};

ImagePriority.getHoverImage = function (images = [{}]) {
  return sortByPriority(images, hoverImagePriority)[0]?.resourcePath;
};

ImagePriority.getPdpStartImage = function (images = [{}]) {
  return sortByPriority(images, pdpStartImagePriority)[0]?.resourcePath;
};

ImagePriority.getAscendingOrder = function (images = [{}], contentfulIndex) {
  return images.sort()[contentfulIndex]?.resourcePath;
};

ImagePriority.getPdpAccordianImage = function (images = [{}]) {
  return sortByPriority(images, pdpAccordianImagePriority)[0]?.resourcePath;
};

ImagePriority.getCartImage = function (images = [{}]) {
  return sortByPriority(images, cartImagePriority)[0]?.resourcePath;
};

ImagePriority.getPdpParralexImages = function (images = [{}]) {
  return sortByPriority(images, pdpParallexPriority)[0]?.resourcePath;
};

ImagePriority.getPdpSimilarImages = function (images = [{}]) {
  return sortByPriority(images, pdpSimilarPriority)[0]?.resourcePath;
};

function sortByPriority(images, priorityList) {
  return images.sort((a, b) => {
    let aIndex = priorityList.indexOf(a.imageType);
    let bIndex = priorityList.indexOf(b.imageType);
    if ((aIndex < bIndex && aIndex !== -1) || bIndex === -1) return -1;
    if ((aIndex > bIndex && bIndex !== -1) || aIndex === -1) return 1;
    return 0;
  });
}

export default ImagePriority;
