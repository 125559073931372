<script>
  import CrossIcon from '../../assets/svg/CrossIcon.svelte';
  import Overlay from '../../components/Overlay.svelte';
  import {slide} from 'svelte/transition';
  import CartItem from './cartItem/CartItem.svelte';
  import {cartOpen} from '../../cart/services/cartStore';
  import {createEventDispatcher} from 'svelte';
  import CountDownCircle from '../../assets/svg/CountDownCircle.svelte';

  export let showPopup;
  export let productTitle;
  export let variant = [];
  export let size;
  export let currencyCode;
  export let price;

  const TOASTER_DISMISSED_EVENT_KEY = 'toastDismissed';
  const TOASTER_TITLE = 'Added to cart';
  const TOASTER_BUTTON_TEXT = 'View cart';

  const dispatch = createEventDispatcher();

  function close() {
    showPopup = false;
    dispatch(TOASTER_DISMISSED_EVENT_KEY);
  }

  function openCart() {
    cartOpen.update(() => true);
    dispatch(TOASTER_DISMISSED_EVENT_KEY);
  }
</script>

{#if showPopup}
  <Overlay overlayTailwind="justify-center" wrapperTailwind="w-full" on:close={close}>
    <section class="mobile-toaster w-screen" in:slide={{duration: 500}} out:slide={{duration: 500}}>
      <header class="p-4 pb-0 flex justify-between items-center">
        <h5 class="flex heading-5">{TOASTER_TITLE}</h5>
        <div class="cross" on:click={close}>
          <CountDownCircle on:done={close}>
            <CrossIcon height="15" width="15" />
          </CountDownCircle>
        </div>
      </header>
      <CartItem
        variantId={variant.id}
        title={productTitle}
        {price}
        {currencyCode}
        image={variant.image}
        selectedColor={variant.colorName ? variant.colorName : variant.colorFamily}
        selectedSize={size ? size : ''}
        shownInToast={true}
      />
      <button class="view-cart-btn attribute-2" on:click={openCart}>
        <p class="text-style-1">{TOASTER_BUTTON_TEXT}</p>
      </button>
    </section>
  </Overlay>
{/if}

<style>
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--jl-black);
    width: 100%;
    min-height: 3.2rem;
    padding: 1rem;
    cursor: pointer;
  }

  button > p {
    color: var(--jl-text-white);
  }

  .mobile-toaster {
    position: absolute;
    top: 0px;
    z-index: 300;
    background: white;
  }

  h1 {
    flex: 7;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.3125rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .cross {
    cursor: pointer;
  }
</style>
