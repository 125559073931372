<script>
  import {createEventDispatcher} from 'svelte';
  import MobileProductFilter from './MobileProductFilter.svelte';
  import TextButton from '../../../../components/TextButton.svelte';

  const dispatch = createEventDispatcher();

  const filters = [
    {
      label: 'Color',
      name: 'color',
      options: [
        {value: 'black', label: 'Black'},
        {value: 'pink', label: 'Pink'},
        {value: 'yellow', label: 'Yellow'},
        {value: 'red', label: 'Red'},
        {value: 'blue', label: 'Blue'},
      ],
    },
    {
      label: 'Size',
      name: 'size',
      options: [
        {value: 'xs', label: 'Xsmall'},
        {value: 's', label: 'Small'},
        {value: 'm', label: 'Medium'},
        {value: 'l', label: 'Large'},
        {value: 'xl', label: 'Xlarge'},
        {value: 'xxl', label: 'XXLarge'},
      ],
    },
  ];

  let filterSelection = {
    color: [],
    size: [],
  };

  $: filterCount = Object.keys(filterSelection).reduce(
    (count, key) => count + filterSelection[key].length,
    0
  );

  function updateFilterSelection(event) {
    filterSelection[event.detail.name] = event.detail.values;
  }

  function clearFilterSelection() {
    Object.keys(filterSelection).forEach((key) => {
      filterSelection[key] = [];
    });
    applyFilterSelection();
  }

  function applyFilterSelection() {
    dispatch('apply-filter-selection', filterSelection);
  }
</script>

<div>
  <MobileProductFilter
    {filters}
    {filterSelection}
    on:update-filter-selection={updateFilterSelection}
  />

  <div>
    <TextButton label="Clear all" on:click={clearFilterSelection} />
    <TextButton label="Apply filter ({filterCount})" on:click={applyFilterSelection} />
  </div>
</div>

<style>
  div > div {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
  }
</style>
