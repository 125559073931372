<script>
  function sendFeedback(e) {
    e.preventDefault();
    window.open(
      'mailto:feedback@jlindeberg.com?subject=JL%20Beta%20Feedback&body=%0A%0AOpened%20on%20page%3A%20' +
        encodeURIComponent(window.location.href)
    );
  }
</script>

<a class="menu-link" on:click={sendFeedback}>Feedback</a>

<style>
  a {
    border: none;
    background-color: transparent;
    position: relative;
    cursor: pointer;
  }
</style>
