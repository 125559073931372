<script>
  import {createEventDispatcher, onMount, setContext} from 'svelte';
  import MobileHeader from './components/MobileHeader.svelte';
  import MenuService from './services/menuService.js';
  import WebHeader from './components/WebHeader.svelte';
  import Cart from '../cart/views/Cart.svelte';
  import {cartOpen} from '../cart/services/cartStore';
  import CartService from '../cart/services/cartService.js';

  export let filterCount = 0;
  export let filterButtonVisible = false;
  export let menuRootNodes = [];
  export let cartItemCount = 0;
  export let shouldOffsetHeader = false;
  export let headerType;
  $: cartItemCount;

  const dispatch = createEventDispatcher();

  onMount(() => {
    new MenuService().getMenu().then((response) => {
      menuRootNodes = response.components;
    });

    new CartService().getCart().then((cart) => (cartItemCount = cart.lineItems.length));
  });

  function menuOpened() {
    dispatch('menuOpened');
  }
  function menuClosed() {
    dispatch('menuClosed');
  }

  setContext('cartOpen', false);

  function applyFilterSelection(event) {
    dispatch('apply-filter-selection', event.detail);
  }

  function closeCart(e) {
    e.preventDefault();
    cartOpen.update(() => false);
  }
</script>

<div class="md:hidden absolute h-full w-full pointer-events-none">
  <MobileHeader
    on:menuOpened={menuOpened}
    on:menuClosed={menuClosed}
    {cartItemCount}
    {filterCount}
    {filterButtonVisible}
    {menuRootNodes}
    on:apply-filter-selection={applyFilterSelection}
  />
</div>
<div class="hidden md:block">
  <WebHeader {cartItemCount} {menuRootNodes} {shouldOffsetHeader} {headerType} />
</div>
<Cart
  on:close-popup={closeCart}
  on:item-removed={(e) => (cartItemCount = e.detail.cartItems.length)}
/>

<style>
</style>
