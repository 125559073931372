<script>
  import imageTransformer from '../../../utils/imageTransformer.js';
  export let image;
</script>

<figure class="w-auto">
  <img class="w-full" src={imageTransformer.getPreview(image)} alt="product-preview" />
</figure>

<style>
  figure {
    background-color: #f0f0f0;
    aspect-ratio: 768 / 1000;
  }
</style>
