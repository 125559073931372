<script>
  import ProductDetailImage from './ProductDetailImage.svelte';
  import ProductDetailAccordion from './ProductDetailAccordion.svelte';
  import ProductDetailSimilarProducts from './ProductDetailSimilarProducts.svelte';

  export let params;
  export let product = {};
  export let selectedVariant;
  export let gridGap;
  let blockItems = params.productDetailItemsCollection.items;

  function getComponent(componentTypeName) {
    switch (componentTypeName) {
      case 'ProductDetailImage':
        return ProductDetailImage;
      case 'ProductDetailAccordion':
        return ProductDetailAccordion;
      case 'ProductDetailSimilarProducts':
        return ProductDetailSimilarProducts;
      default:
        return null;
    }
  }
</script>

<div
  class="grid gap-{gridGap.default} pt-{gridGap.default} md:grid-cols-{blockItems.length} md:gap-{gridGap.md} md:pt-{gridGap.md}"
>
  {#each blockItems as blockItem}
    <div class="table min-h-[25rem]">
      <div class="middle-alignment">
        <svelte:component
          this={getComponent(blockItem.type)}
          props={blockItem}
          {product}
          {selectedVariant}
        />
      </div>
    </div>
  {/each}
</div>

<style>
  .middle-alignment {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
  }
</style>
