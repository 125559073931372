const PrecacheHandler = function () {};

function _getElementByResourceUrl(url) {
  return document.querySelector(`script[resource='${url}']`);
}

PrecacheHandler.hasCacheForResourceUrl = function (url) {
  return _getElementByResourceUrl(url) !== null;
};

PrecacheHandler.getCacheForResourceUrl = function (url) {
  if (PrecacheHandler.hasCacheForResourceUrl(url) === false)
    return Promise.reject(new Error('No cache exists for the given resource'));

  const cache = _getElementByResourceUrl(url).textContent?.slice(2) || undefined;

  return Promise.resolve(cache);
};

PrecacheHandler.getJsonCacheForResourceUrl = function (url) {
  return PrecacheHandler.getCacheForResourceUrl(url).then((cache) => {
    if (typeof cache === 'undefined') throw new Error('Cache is empty.');

    return JSON.parse(cache);
  });
};

export default PrecacheHandler;
