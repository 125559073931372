<script>
  import MobileFooterAccordionItem from './MobileFooterAccordionItem.svelte';

  export let items;

  let selectedIndex = null;
  function setSelected(index) {
    if (selectedIndex === index.detail) {
      selectedIndex = null;
      return;
    }
    selectedIndex = index.detail;
  }
</script>

<div>
  {#each items as section, index}
    <MobileFooterAccordionItem {section} {index} {selectedIndex} on:selected={setSelected} />
  {/each}
</div>

<style>
</style>
