<script>
  import {slide} from 'svelte/transition';
  import {createEventDispatcher} from 'svelte';
  import ArrowDown from './../../../../assets/svg/ArrowDown.svelte';

  const dispatch = createEventDispatcher();

  export let parentNode;
  export let node;
  export let nodeIndex;
  export let selectedIndex;

  $: isOpen = nodeIndex === selectedIndex;

  function closeMenu() {
    dispatch('close-menu');
  }

  const toggle = () => {
    dispatch('selected', nodeIndex);
  };

  function getNodeUrl(node) {
    const parentValue = parentNode.linkValue || parentNode.label;
    const nodeValue = node.linkValue || node.label;
    return `/category/${parentValue}/${nodeValue}`.replace(/ /g, '');
  }

  function getChildNodeUrl(node, childNode) {
    const childValue = childNode.linkValue || childNode.label;
    return `${getNodeUrl(node)}/${childValue}`.replace(/ /g, '');
  }
</script>

<div>
  <a href={getNodeUrl(node)} class="heading-3" on:click={closeMenu}>{node.label}</a>
  <button on:click={toggle} aria-expanded={isOpen}>
    <ArrowDown height="8" width="12" selected={isOpen} />
  </button>
</div>
{#if isOpen}
  <ul class="flex flex-col" transition:slide={{duration: 300}}>
    {#each node.children as childNode, childNodeIndex}
      <li>
        <a class="text-style-4" href={getChildNodeUrl(node, childNode)} on:click={closeMenu}
          >{childNode.label}</a
        >
      </li>
    {/each}
  </ul>
{/if}

<style>
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-bottom: 0.0625rem solid var(--jl-light-grey);
  }

  div > a {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  div > a.heading-3 {
    line-height: 100%;
  }

  button {
    border: none;
    background: none;
    color: inherit;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 6.25rem;
    height: 2.875rem;
  }

  ul {
    margin-bottom: 0.375rem;
  }

  li {
    text-align: left;
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-bottom: 0.0625rem solid var(--jl-light-grey);
  }

  li:first-of-type {
    height: calc(3.125rem - 0.375rem);
  }

  a {
    width: 100%;
    padding: 0.875rem 0;
  }
</style>
