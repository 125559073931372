const sizes = [
  {short: 'XXS', long: 'XXSMALL'},
  {short: 'XS', long: 'XSMALL'},
  {short: 'S', long: 'SMALL'},
  {short: 'M', long: 'MEDIUM'},
  {short: 'L', long: 'LARGE'},
  {short: 'XL', long: 'XLARGE'},
  {short: 'XXL', long: 'XXLARGE'},
];

function sizeParser(size) {
  if (sizes.find((s) => s.short === size)) {
    return sizes.find((s) => s.short === size).long;
  }
  return size;
}

export default sizeParser;
